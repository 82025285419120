/* Sidebar Container */
.containerrr {
  width: auto; /* Or any other desired width */
}

/* Sidebar Navigation */
.sidebar {
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid gold;
  padding: 0 16px;
  overflow-y: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(to bottom, #000000, #333333); /* Black gradient */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 60px; /* Adjust margin to avoid overlapping with top content */
}

.main-content {
  margin-left: 240px; /* Adjust main content margin to accommodate sidebar */
}

.sidebar__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 56px -30px;
}

.sidebar__logo-image {
  height: auto;
  width: 150px;
}

.side-nav {
  display: flex;
  flex-direction: column;
}

.side-nav__header {
  font-size: 14px;
  line-height: 21px;
  color: gold; /* Gold color */
  padding-bottom: 10px;
  display: inline-block;
}

.side-nav__item {
  list-style: none;
  color: gold; /* Gold color */
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px 0 14px 16px;
  border-radius: 10px;
  transition: all 0.4s;
  cursor: pointer;
  white-space: nowrap;
  margin-top: 10px;
  background-color: #333333; /* Dark background */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.side-nav__item > svg {
  flex-shrink: 0;
}

.side-nav__item.last-item {
  margin-bottom: 32px;
}

.side-nav__item:not(:last-child) {
  margin-bottom: 4px;
}

/* Hover and Active Styles */
.side-nav__item:hover,
.side-nav__item-active {
  background-color: gold; /* Gold background */
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Toggle Button */
.sidebar-toggle {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.container.sidebar-open .sidebar {
  transform: translateX(0);
}

/* Hover Effect for Links */
.sidebar_soon {
  text-decoration: none;
  color: gold; /* Gold color */
}

.sidebar_soon:hover {
  color: black;
}

/* Style the Link component */
.link {
  text-decoration: none; /* Remove underline */
  color: gold; /* Gold color */
  transition: color 0.4s;
}

/* Hover effect for Link */
.link:hover {
  color: black;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 200px;
    transform: translateX(-100%);
    z-index: 1000;
  }

  .sidebar-open .sidebar {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
  }

  .sidebar-toggle {
    display: block;
    position: fixed;
    top: 94px;
    left: 10px;
    background-color: gold; /* Gold background color */
    color: black; /* Text color */
    font-size: 24px;
    padding: 5px 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .sidebar-toggle:hover {
    background-color: gold; /* Darker gold on hover */
  }
}

.sidebar-close {
  display: none;
}

@media (max-width: 768px) {
  .sidebar-close {
    display: block;
    color: black;
  }

  .sidebar-close:before,
  .sidebar-close:after {
    background-color: gold; /* Gold color */
  }

  .sidebar-close:hover:before,
  .sidebar-close:hover:after {
    background-color: gold; /* Darker gold color on hover */
  }

  .sidebar-close {
    position: absolute;
    right: 15px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: gold;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1001;
    font-size: 40px;
  }

  .sidebar-close:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .sidebar-close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .sidebar__logo-image {
    height: auto;
    width: 125px;
  }

  .sidebar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0 55px -10px;
  }

  .side-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0.1rem;
  }

  .side-nav__header {
    font-size: 18px;
    line-height: 21px;
    color: gold; /* Gold color */
    padding-bottom: 10px;
    display: inline-block;
  }
}
