:root {
  --primary-color: #e7b32b;
  --secondary-color: #34495e;
  --text-color: #fff;
  --gradient: linear-gradient(45deg, #e7b32b, #ffcc5c); /* Gradient for buttons and overlays */
}

.life-insurance-try-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12rem;
  padding-right: 12rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.life-insurance-try-content {
  max-width: 50%;
}

.life-insurance-try-subtitle {
  font-size: 1.5rem;
  color: #e7b32b;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.life-insurance-try-title {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.3;
}

.life-insurance-try-description {
  font-size: 1.2rem;
  color: #5d5b5b;
  margin-bottom: 20px;
  line-height: 1.6;
}

.life-insurance-try-button {
  font-size: 1rem;
  padding: 12px 24px; /* Slightly larger padding */
  color: #000;
  font-weight: 600;
  background: var(--gradient); /* Gradient background */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.life-insurance-try-button:hover {
  transform: translateY(-2px); /* Slight lift effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.life-insurance-try-image-container {
  max-width: 45%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.life-insurance-try-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)); /* Gradient overlay */
  z-index: 1;
  border-radius: inherit;
}

.life-insurance-try-image-container:hover {
  transform: scale(1.02); /* Slight zoom effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.life-insurance-try-image {
  width: 100%;
  border-radius: 12px;
  display: block;
  transition: transform 0.3s ease;
}

.life-insurance-try-image-container:hover .life-insurance-try-image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Mobile View (Phone) */
@media (max-width: 768px) {
  .life-insurance-try-container {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .life-insurance-try-content {
    max-width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .life-insurance-try-subtitle {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .life-insurance-try-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .life-insurance-try-description {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .life-insurance-try-button {
    font-size: 0.9rem;
    padding: 10px 20px;
    width: 100%;
    margin-top: 1rem;
  }

  .life-insurance-try-image-container {
    max-width: 100%;
    margin-top: 1rem;
  }

  .life-insurance-try-image {
    border-radius: 8px;
  }
}