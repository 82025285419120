/* Individual_Health_Info.css */

/* General container styling */
.Individual-Health-containerr {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 8rem 2rem;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #1a1a1a;
}

/* Section styling */
.Individual-Health-section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Individual-Health-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #2c3e50;
}

/* Section content layout */
.Individual-Health-section-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Image styling */
.Individual-Health-section-img {
    max-width: 300px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Text content styling */
.Individual-Health-section-text {
    flex: 1;
}

.Individual-Health-section-para {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

/* List styling */
.Individual-Health-section-content ul {
    list-style-type: none;
    padding: 0;
}

.Individual-Health-section-content ul li {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
    color: #555;
}

.Individual-Health-section-content ul li strong {
    color: #2c3e50;
}

/* Call-to-Action section styling */
.Individual-Health-contact-container {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background-color: #2c3e50;
    border-radius: 8px;
    color: #fff;
}

.Individual-Health-contact-container h3 {
    font-size: 20px;
    margin-bottom: 20px;
}

.Individual-Health-contact-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #2c3e50;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.Individual-Health-contact-button:hover {
    background-color: #f0f0f0;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .Individual-Health-section-content {
        flex-direction: column;
        text-align: center;
    }

    .Individual-Health-section-img {
        max-width: 100%;
    }

    .Individual-Health-section-text {
        text-align: left;
    }
}