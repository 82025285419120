:root {
  --white: #ffffff;
  --black1: #333333;
  --blue: #3498db;
  --light-blue: #eaf4fb;
  --grey: #f5f5f5;
  --dark-grey: #a9a9a9;
  --gradient: linear-gradient(135deg, #6e8efb, #a777e3);
  --gold: #ffe600;
}

.Pms_dashboard_card {
    background: var(--white);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    margin: 20px;
  }

.Pms_dashboard_section_title {
    font-weight: 600;
    color: var(--black1);
    margin-bottom: 15px;
    margin: 20px;
    border-bottom: 2px solid var(--gold);
    padding-bottom: 5px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pms_dashboard_area {
    background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d);
    width: 85%;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    left: 175px;
    top: 20px;
  }
  
  .pms_dashboard_circles {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .pms_dashboard_circles li {
    position: absolute;
    background: rgba(255, 255, 255, 0.44);
    animation: animate 25s linear infinite;
    bottom: -150px;
    opacity: 0.7;
    display: none;
  }
  
  /* Circle animations */
  .pms_dashboard_circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  
  .pms_dashboard_circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  
  .pms_dashboard_circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  
  .pms_dashboard_circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }





  .pms_dashboard_search-container {
    /* margin-top: 10px; */
    width: 50%;
}

.pms_dashboard_search-input {
    width: 100%;
    padding: 8px 15px;
    font-size: 14px;
    border: 1px solid #aca9a9;
    border-radius: 25px;
    outline: none;
    transition: all 0.3s ease;
}

.pms_dashboard_search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.pms_dashboard_search-input::placeholder {
    color: #aca9a9;
}
