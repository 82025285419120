/* Container for the entire carousel */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensures the container takes up full viewport height */
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Wrapper for the video */
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Video styling */
.carousel-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Gradient overlay to darken the video */
.carousel-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9));
  z-index: 1;
}

/* carousel-content styling */
.carousel-content {
  position: absolute;
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Offset by 50% of its own width/height */
  text-align: center;
  z-index: 2;
  color: #fff;
  padding: 0 30px;
  width: 80%; /* You can adjust this as needed */
  opacity: 1; /* Initially visible */
  transform: translate(-50%, -50%) scale(1); /* Full scale */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Smooth transition for disappearing effect */
}

/* carousel-content disappearing effect on scroll */
.carousel-content.scrolled {
  opacity: 0; /* Fade out */
  transform: translate(-50%, -50%) scale(0.8); /* Scale down */
}

/* carousel-subtitle styling */
.carousel-subtitle {
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 3px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #e7b32b; /* Golden highlight */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); /* Shadow for better readability */
}

/* Main title styling */
.carousel-main-title {
  font-size: 3rem; /* Larger title for a stronger impact */
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Make text pop */
}

/* Call to action (CTA) button styling */
.carousel-cta-button {
  padding: 15px 35px;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  text-decoration: none;
  letter-spacing: 1px; /* Spaced-out letters for modern feel */
}

.carousel-cta-button:hover {
  background: linear-gradient(45deg, #feb47b, #ff7e5f);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.4);
  transform: translateY(-4px);
  opacity: 0.9; /* Slight transparency effect */
}

/* Media query for tablet */
@media (max-width: 768px) {
  
  .carousel-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ensures the container takes up full viewport height */
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
  }
  

  .carousel-main-title {
    font-size: 2.2rem;
  }

  .carousel-subtitle {
    font-size: 1.3rem;
  }

  .carousel-cta-button {
    font-size: 1rem;
    padding: 12px 30px;
  }
}

/* Media query for mobile */
@media (max-width: 480px) {
  .carousel-content {
    padding: 0 15px;
    width: 90%;
  }

  .carousel-main-title {
    font-size: 1.6rem;
  }

  .carousel-cta-button {
    font-size: 0.9rem;
    padding: 10px 25px;
  }
}
