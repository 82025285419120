.Growth-main-container {
    padding: 8rem;
    background: linear-gradient(135deg, #1a1a1a, #000);
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

/* Top Section */
.Growth-top-section {
    display: flex;
    gap: 40px;
    margin-bottom: 60px;
    align-items: center;
}

.Growth-image-container {
    flex: 1;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.Growth-main-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition: transform 0.5s ease;
}

.Growth-main-image:hover {
    transform: scale(1.05);
}

.Growth-text-container {
    flex: 1;
    padding: 30px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.Growth-text-container h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #e7b32b;
    font-weight: 600;
}

.Growth-text-container p {
    font-size: 16px;
    margin-bottom: 30px;
    color: #ccc;
    line-height: 1.6;
}

.Growth-contact-button {
    background: linear-gradient(135deg, #e7b32b, #d49b1f);
    color: #1a1a1a;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Growth-contact-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(231, 179, 43, 0.3);
}

/* Feature Section */
.Growth-feature-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    gap: 20px;
}

.Growth-feature-box {
    flex: 1;
    padding: 30px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Growth-feature-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 24px rgba(231, 179, 43, 0.2);
}

.Growth-feature-box h3 {
    margin: 20px 0;
    font-size: 24px;
    color: #e7b32b;
    font-weight: 600;
}

.Growth-feature-box p {
    font-size: 14px;
    color: #ccc;
    line-height: 1.6;
}

.Growth-icon {
    font-size: 40px;
    color: #e7b32b;
    transition: transform 0.3s ease;
}

.Growth-feature-box:hover .Growth-icon {
    transform: scale(1.2);
}

/* Stats Section */
.Growth-stats-section {
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    padding: 40px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.Growth-stat-box {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.Growth-stat-box h3 {
    font-size: 36px;
    color: #e7b32b;
    margin-bottom: 10px;
    font-weight: 600;
}

.Growth-stat-box p {
    font-size: 16px;
    color: #ccc;
}

/* Responsive Design */
@media (max-width: 768px) {
    .Growth-main-container {
        padding: 4rem;
    }

    .Growth-top-section {
        flex-direction: column;
        gap: 20px;
    }

    .Growth-feature-section {
        flex-direction: column;
        gap: 20px;
    }

    .Growth-stats-section {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .Growth-main-container {
        padding: 2rem;
    }

    .Growth-text-container h2 {
        font-size: 24px;
    }

    .Growth-text-container p {
        font-size: 14px;
    }

    .Growth-contact-button {
        font-size: 14px;
        padding: 10px 20px;
    }

    .Growth-feature-box h3 {
        font-size: 20px;
    }

    .Growth-feature-box p {
        font-size: 12px;
    }

    .Growth-stat-box h3 {
        font-size: 24px;
    }

    .Growth-stat-box p {
        font-size: 14px;
    }
}