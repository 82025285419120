.about-us-header {
  text-align: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none; /* Prevent interaction with the image */
}

.about-us-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 2;
}

.about-us-content {
  position: relative;
  z-index: 5;
  text-align: center;
  max-width: 800px;
  padding: 20px;
  animation: fadeIn 1.5s ease-in-out;
}

.about-us-content h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  color: #e7b32b; /* Gold-like color for the title */
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

.about-us-content p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #f2f2f2;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #e7b32b;
  color: #000;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #d4a025;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-us-content h1 {
    font-size: 3rem;
  }

  .about-us-content p {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .about-us-content h1 {
    font-size: 2.5rem;
  }

  .about-us-content p {
    font-size: 1.1rem;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .about-us-content h1 {
    font-size: 2rem;
  }

  .about-us-content p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}