/* Existing CSS */

.navbar {
  background: linear-gradient(
    rgba(36, 36, 36, 0.95),
    rgba(36, 36, 36, 0.95)
  );
  height: 80px;
  margin: 20px;
  border-radius: 16px;
  padding: 0.5rem;
}

.navbar-brand {
  font-weight: 500;
  color: #000;
  font-size: 24px;
  transition: 0.3s color;
}

.login-button {
  background-color: #000;
  color: #ffff;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 50px;
  text-decoration: none;
  transition: 0.3s background-color;
  margin-left: 10px;
  border: 2px solid #e7b32b;
}

.login-button:hover {
  background-color: rgb(32, 32, 32);
}

.navbar-toggler {
  border: none;
  font-size: 1.25rem;
  border-radius: 8px;
  padding: 0.5rem;
}

.navbar-toggler:focus,
.btn-close:focus {
  box-shadow: none;
  outline: none;
}

.nav-link {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.active {
  color: #e7b32b !important;
}

.mt-3 {
  margin-top: 0.1rem !important;
}

.pe-3 {
  padding-left: 4rem !important;
}

// Change the color of the toggler icon
.navbar-toggler-icon {
  filter: invert(1); // Makes the icon white
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    font-size: 18px;
  }
}

.navbar-logo {
  height: auto;
  width: 130px;
}

@media (max-width: 768px) {
  .navbar {
    margin: 10px;
    border-radius: 8px;
  }

  .navbar-toggler {
    padding: 0.5rem;
    color: white !important;
  }

  .offcanvas {
    width: 75% !important;
    background-color: black; /* Ensure offcanvas background is black */
  }

  .offcanvas-header {
    background-color: black; /* Ensure header background is black */
  }

  .offcanvas-body {
    padding: 1rem;
    background-color: black; /* Ensure body background is black */
  }

  .btn-close {
    filter: invert(1); /* This will make the X button white */
  }

  .nav-link {
    font-size: 16px;
    padding: 0.5rem 1rem;
  }

  .login-button {
    font-size: 14px;
    padding: 6px 16px;
    margin-left: 5px;
  }

  .navbar-logo {
    width: 100px;
  }
}