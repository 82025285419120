.life-in-custom-hero {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  min-height: 620px; /* Minimum height for smaller screens */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6); /* Fallback background */
}

.life-in-custom-hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.life-in-custom-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%); /* Darken image for better contrast */
  transition: transform 0.8s ease, filter 0.8s ease;
}

.life-in-custom-hero:hover .life-in-custom-hero-image {
  transform: scale(1.1); /* Smooth zoom effect on hover */
  filter: brightness(70%); /* Slightly brighten image on hover */
}

.life-in-custom-hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  max-width: 800px;
  padding: 20px;
  animation: fadeIn 1.5s ease-out; /* Fade-in animation */
}

.life-in-custom-hero-title {
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6); /* Text shadow for depth */
  line-height: 1.2;
}

.life-in-custom-hero-title .highlight {
  color: #e7b32b; /* Gold color for emphasis */
  background: linear-gradient(90deg, #e7b32b, #ff7f00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glow 2s infinite alternate; /* Glow effect */
}

.life-in-custom-hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 40px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #f0f0f0; /* Light gray for better contrast */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.life-in-custom-hero-button {
  padding: 15px 40px;
  font-size: 1.1rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ff7f00, #e7b32b); /* Gradient button */
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 127, 0, 0.4); /* Subtle shadow */
}

.life-in-custom-hero-button:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 20px rgba(255, 127, 0, 0.6); /* Enhanced shadow on hover */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(231, 179, 43, 0.6);
  }
  100% {
    text-shadow: 0 0 20px rgba(231, 179, 43, 0.9);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .life-in-custom-hero-title {
    font-size: 3rem;
  }

  .life-in-custom-hero-subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .life-in-custom-hero-button {
    font-size: 1rem;
    padding: 12px 30px;
  }
}

@media (max-width: 480px) {
  .life-in-custom-hero {
    height: 80vh; /* Adjust height for smaller screens */
    min-height: 400px;
  }

  .life-in-custom-hero-title {
    font-size: 2.5rem;
  }

  .life-in-custom-hero-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .life-in-custom-hero-button {
    font-size: 0.9rem;
    padding: 10px 25px;
  }
}