.About_Vision_container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 6rem 12rem;
    font-family: 'Arial', sans-serif;
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .About_Vision_left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .About_Vision_card {
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .About_Vision_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
  }
  
  .About_Vision_vision,
  .About_Vision_mission {
    background-color: #ffece5;
    color: #1a1a1a;
  }
  
  .About_Vision_history {
    background-color: #000;
    color: #fff;
  }
  
  .About_Vision_right-section {
    flex: 1;
    background-color: rgba(191, 175, 175, 0.8);
    background-image: url('../../assets/images/about_us.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 2rem;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .About_Vision_right-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 12px;
    z-index: 1;
  }
  
  .About_Vision_right-section > * {
    position: relative;
    z-index: 2;
  }
  
  .About_Vision_right-section .About_Vision_h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #e7b32b;
    font-weight: bold;
    line-height: 1.3;
  }
  
  .About_Vision_right-section .About_Vision_para {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .About_Vision_button {
    background-color: #e7b32b;
    color: #000;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .About_Vision_button:hover {
    background-color: #d4a025;
    transform: translateY(-3px);
  }
  
  .About_Vision_h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #e7b32b;
  }
  
  .About_Vision_para {
    font-size: 1rem;
    line-height: 1.6;
    color: inherit;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .About_Vision_container {
      padding: 4rem 6rem;
    }
  }
  
  @media (max-width: 1024px) {
    .About_Vision_container {
      padding: 4rem 3rem;
    }
  
    .About_Vision_right-section .About_Vision_h1 {
      font-size: 2rem;
    }
  
    .About_Vision_right-section .About_Vision_para {
      font-size: 1rem;
    }
  
    .About_Vision_h2 {
      font-size: 1.5rem;
    }
  
    .About_Vision_para {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    .About_Vision_container {
      flex-direction: column;
      padding: 3rem 2rem;
      gap: 2rem;
    }
  
    .About_Vision_right-section {
      padding: 1.5rem;
    }
  
    .About_Vision_right-section .About_Vision_h1 {
      font-size: 1.75rem;
    }
  
    .About_Vision_right-section .About_Vision_para {
      font-size: 0.95rem;
    }
  
    .About_Vision_button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .About_Vision_container {
      padding: 2rem 1rem;
    }
  
    .About_Vision_right-section .About_Vision_h1 {
      font-size: 1.5rem;
    }
  
    .About_Vision_right-section .About_Vision_para {
      font-size: 0.9rem;
    }
  
    .About_Vision_h2 {
      font-size: 1.25rem;
    }
  
    .About_Vision_para {
      font-size: 0.9rem;
    }
  }