/* Header Section */
.new-header {
    text-align: center;
    padding: 3rem;
    background-color: #000000;
}

.new-title {
    font-size: 48px;
    font-weight: bold;
    color: #e7b32b;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.new-subtitle {
    font-size: 20px;
    color: #7f8c8d;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Introduction Section */
.new-intro-section {
    margin-bottom: 60px;
}

.new-intro-text {
    font-size: 18px;
    color: #fff;
    line-height: 1.8;
    text-align: center;
    padding: 0 20px;
}

/* Features and Types Sections */
.new-features-section,
.new-types-section {
    margin-bottom: 80px;
}

.new-section-title {
    font-size: 32px;
    color: #e7b32b;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;
}

/* Grid Layout */
.new-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards in one row */
    gap: 30px;
    justify-items: center;
}

.new-types {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards in one row */
    gap: 30px;
    justify-items: center;
}

/* Cards Design */
.new-feature-card,
.new-type-card {
    background-color: #1a1a1a;
    padding: 35px;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease-in-out;
    /* cursor: pointer; */
    border: 1px solid #ecf0f1;
}

.new-feature-card:hover,
.new-type-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}

.new-feature-card h3,
.new-type-card h5 {
    font-size: 24px;
    color: #e7b32b;
    margin-bottom: 15px;
    font-weight: 600;
}

.new-feature-card p,
.new-type-card p {
    font-size: 16px;
    color: #fff;
    line-height: 1.6;
}

/* Image Styling */
.new-type-image {
    width: 100%;
    max-width: 80px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.new-type-card:hover .new-type-image {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}








.carousell-new-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background-color: #000000; /* Optional: background for visual appeal */
}

.home_insurance-section-title{
    font-size: 32px;
    color: #e7b32b;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;
}

.carousell-new {
    height: 60vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    perspective: 35rem;
}

.carousell-new__cards {
    position: relative;
    width: 20rem;
    height: 25rem; /* Set a consistent height for the cards */
    transform-style: preserve-3d;
    transform: translateZ(-25rem);
    transition: transform 1s ease-in-out;
}

.carousell-new__card {
    width: 100%;
    height: 80%;
    position: absolute;
    background-color: #000;
    border: 2px solid white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure content starts from the top */
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden; /* Prevents content from spilling outside */
}

.carousell-new__img-container {
    width: 100%;
    height: 50%; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures the image doesn’t spill outside */
    border-radius: 24px 24px 0 0; /* Matches card’s top rounded corners */
    background-color: #1a1a1a; /* Optional: Background color for the image container */
}

.carousell-new__img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container while maintaining aspect ratio */
}


.carousell-new__content {
    flex-grow: 1; /* Push content to take up the remaining space */
    text-align: center;
    margin-top: 10px;
    color: #fff; /* Ensure text is visible on dark backgrounds */
    z-index: 1; /* Ensure text is on top of the image */
}

.carousell-new__title {
    font-size: 1.5rem;
    font-weight: bold;
    color: gold;
    margin: 10px 0; /* Add uniform margin around the title */
}

.carousell-new__para {
    font-size: 1rem;
    color: white;
    margin: 10px 0; /* Add uniform margin around the paragraph */
}

/* Transform for each card */
.carousell-new__card:nth-child(1) {
    transform: rotateY(0deg) translateZ(25rem);
}

.carousell-new__card:nth-child(2) {
    transform: rotateY(30deg) translateZ(25rem);
}

.carousell-new__card:nth-child(3) {
    transform: rotateY(60deg) translateZ(25rem);
}

.carousell-new__card:nth-child(4) {
    transform: rotateY(90deg) translateZ(25rem);
}


.insurance-cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
    gap: 50px;
    background-color: #000;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Soft shadow */
    overflow: hidden;
  }
  
  .insurance-cta-image-container {
    flex: 1;
    max-width: 400px;
    border-radius: 10px;
  }
  
  .insurance-cta-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .insurance-cta-image:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  .insurance-cta-content {
    flex: 2;
    max-width: 840px;
  }
  
  .insurance-cta-title {
    font-size: 2rem;
    font-weight: 700;
    color: #e7b32b;
    margin-bottom: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .insurance-cta-description {
    font-size: 1rem;
    color: #fff;
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 30px;
    opacity: 0.9;
  }
  
  .insurance-cta-benefits {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .insurance-cta-benefits li {
    font-size: 1rem;
    color: #dcdcdc;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .insurance-cta-benefits li strong {
    color: #e7b32b; /* Highlighted strong text */
    margin-right: 8px;
  }
  
  .insurance-cta-footer {
    font-size: 1.2rem;
    color: #ff0000;
    margin-bottom: 20px;
  }
  
  .insurance-cta-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: white;
    background-color: #e7b32b;
    border: none;
    border-radius: 25px; /* Round button */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  @media (max-width: 1024px) {
    .insurance-cta-container {
      flex-direction: column;
      padding: 40px;
      text-align: center;
    }
  
    .insurance-cta-image-container {
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .insurance-cta-container {
      flex-direction: column;
      padding: 20px; /* Less padding for smaller screens */
      gap: 20px; /* Reduced gap between elements */
    }
  
    .insurance-cta-image-container {
      max-width: 100%; /* Allow image container to be full width on small screens */
      margin-bottom: 20px; /* Space between image and content */
    }
  
    .insurance-cta-title {
      font-size: 1.2rem; /* Reduce font size for mobile */
    }
  
    .insurance-cta-description {
      font-size: 0.9rem; /* Adjust font size for readability */
    }
  
    .insurance-cta-button {
      padding: 12px 25px;
      font-size: 1rem; /* Adjust button font size */
    }
  
    .insurance-cta-benefits li {
      text-align: left;
      font-size: 0.8rem; /* Slightly smaller font size for benefits */
    }
  
    .insurance-cta-footer {
      font-size: 1rem; /* Smaller footer text */
    }
  }

  
/* Benefits Section */
.new-benefits-section {
    padding: 80px 40px;
    text-align: center;
    color: #f0f0f0;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.new-benefits-inner-section {
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    align-items: flex-start;
    /* border: 2px solid white; */
    padding: 40px;
    border-radius: 24px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* background-color: rgba(255, 255, 255, 0.1); */
}

/* Improved Image UI */
.new-benefits-section img {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 16px;
    margin-bottom: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    object-fit: cover;
}

/* Hover effect for the image */
.new-benefits-section img:hover {
    transform: scale(1.05);
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.5);
}

/* New Benefits List */
.new-benefits-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
    color: #d1d5db;
    font-size: 15px;
    margin: 0;
}

.new-benefits-list li {
    padding-left: 30px;
    margin-bottom: 20px;
    transition: color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for list items */
.new-benefits-list li:hover {
    color: #e7b32b;
    transform: translateX(8px);
}

/* Call to Action Button */
.new-cta-section {
    text-align: left;
    margin-top: 40px;
    text-align: center;
}

.new-benefits-list li:last-child {
    color: red;
}


.new-cta-button {
    background: linear-gradient(135deg, #e7b32b, #e7b32b);
    color: #ffffff;
    font-size: 22px;
    padding: 20px 60px;
    text-decoration: none;
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

.new-cta-button:active {
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .new-header {
        padding: 2rem;
    }

    .new-title {
        font-size: 36px;
    }

    .new-subtitle {
        font-size: 18px;
    }

    .new-intro-text {
        font-size: 16px;
    }

    .new-features,
    .new-types {
        grid-template-columns: 1fr; /* Only 1 card per row on smaller screens */
    }

    .new-cta-button {
        font-size: 18px;
        padding: 15px 30px;
    }

    .new-benefits-section img {
        max-width: 300px;
    }

    .new-benefits-section {
        padding: 20px 20px;
        text-align: center;
        color: #f0f0f0;
        border-radius: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .new-benefits-inner-section {
        display: flex;
        flex-direction: row;
        gap: 60px;
        justify-content: center;
        align-items: flex-start;
        border: 2px solid white;
        padding: 20px;
        border-radius: 24px;
        box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.1);
    }

    .new-benefits-list li {
        padding-left: 0px !important;
        margin-bottom: 20px;
        transition: color 0.3s ease, transform 0.3s ease;
        text-align: justify;
    }

    .new-cta-section {
        text-align: center;
        margin-top: 40px;
    }

    /* Fix text overflow in card descriptions */
    .new-type-card p {
        overflow: hidden; /* Hide overflowed text */
        text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
        white-space: nowrap; /* Prevent wrapping of long text */
    }

    .home_insurance-type-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;
        height: auto; /* Allow card to expand based on content */
        max-height: 300px; /* Limit max height for small screens */
        overflow: hidden; /* Prevent content from overflowing */
    }

    /* Ensure image doesn't overflow */
    .new-type-image {
        max-width: 30%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .new-title {
        font-size: 30px;
    }

    .new-subtitle {
        font-size: 16px;
    }

    .new-feature-card,
    .new-type-card {
        padding: 25px;
    }

    .new-benefits-list {
        font-size: 14px;
    }

    .new-cta-button {
        font-size: 16px;
        padding: 12px 25px;
    }

    .new-benefits-inner-section {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        gap: 40px;
    }

    .new-benefits-section img {
        max-width: 100%;
    }

    /* Adjust carousel layout to show only 1 card */
    .home_insurance-carousel-wrapper {
        overflow: hidden;
    }

    .home_insurance-carousel {
        display: flex;
        flex-direction: column;
        gap: 10px; /* Space between carousel items */
    }

    .home_insurance-type-card {
        width: 100%; /* Ensure the card occupies full width */
    }
}
