.info-carousel-container {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Background Video */
.info-background-video {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
  object-fit: cover; /* Ensure the video covers the container */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Content Overlay */
.info-content-overlay {
  position: relative;
  z-index: 2;
  padding: 60px 180px;
  color: white;
  background: rgba(0, 0, 0, 0.75); /* Increased opacity for a darker overlay */
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align items to the right */
}

/* Header Section */
.info-header-section h1 {
  font-size: 0.9em;
  text-align: right;
  margin-bottom: 10px;
  color: #fff; /* White text for contrast */
}

.info-header-section h2 {
  font-size: 3em;
  text-align: right;
  margin-bottom: 20px;
  color: #f1c40f; /* Yellow color for emphasis */
}

/* Call-to-Action Button */
.info-cta-button {
  background-color: #000;
  color: white;
  font-size: 1.2em;
  padding: 15px 30px;
  border: 1px solid #f1c40f;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Add some spacing between header and button */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .info-content-overlay {
    padding: 60px 100px; /* Reduce padding for medium screens */
  }

  .info-header-section h1 {
    font-size: 1em;
  }

  .info-header-section h2 {
    font-size: 2.5em;
  }
}

@media (max-width: 768px) {
  .info-content-overlay {
    padding: 40px 20px; /* Reduce padding for smaller screens */
    align-items: center; /* Center align content on small screens */
    text-align: center; /* Center text */
  }

  .info-header-section h1 {
    font-size: 0.9em;
  }

  .info-header-section h2 {
    font-size: 2em;
  }

  .info-cta-button {
    font-size: 1em;
    padding: 12px 24px;
  }
}

@media (max-width: 480px) {
  .info-header-section h1 {
    font-size: 0.8em;
    text-align: center;
  }

  .info-header-section h2 {
    font-size: 1.5em;
    text-align: center;
  }

  .info-cta-button {
    font-size: 0.9em;
    padding: 10px 20px;
  }
}