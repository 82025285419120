/* Import a modern font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.child-plans-container {
    margin: 0 auto;
    padding: 8rem 4rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.8;
    background-color: #1a1a1a;
    color: #fff;
}

/* Child Plans Section */
.child-plans-section {
    margin-bottom: 40px;
    padding: 30px;
    background: linear-gradient(145deg, #2c2c2c, #1a1a1a);
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.child-plans-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

/* Section Headings */
.child-plans-section h2 {
    font-size: 2.2rem;
    font-weight: 600;
    color: #ffd700; /* Gold color for headings */
    margin-bottom: 20px;
    border-bottom: 3px solid #ffd700;
    padding-bottom: 10px;
    text-align: center;
}

/* Section Content */
.section-content {
    display: flex;
    align-items: center;
    gap: 40px;
}

/* Image Styling */
.section-img {
    width: 45%;
    max-width: 450px;
    height: auto;
    border-radius: 12px;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); */
    transition: transform 0.3s ease;
}

/* .section-img:hover {
    transform: scale(1.05);
} */

/* Text Content */
.section-text {
    flex: 1;
}

/* Paragraph Styling */
.child-plans-section p {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 20px;
    color: #e0e0e0;
    line-height: 1.8;
}

/* Lists */
.child-plans-section ul {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 20px;
}

.child-plans-section ul li {
    margin-bottom: 12px;
    color: #e0e0e0;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.child-plans-section ul li::before {
    content: "•";
    color: #ffd700;
    font-size: 1.5rem;
    margin-right: 10px;
}

/* Table Styling */
.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #2c2c2c;
    border-radius: 12px;
    overflow: hidden;
}

.comparison-table th,
.comparison-table td {
    border: 1px solid #444;
    padding: 15px;
    text-align: left;
    color: #fff;
}

.comparison-table th {
    background-color: #1a1a1a;
    color: #ffd700;
    font-weight: 600;
}

.comparison-table tr:nth-child(even) {
    background-color: #2c2c2c;
}

.comparison-table tr:hover {
    background-color: #3a3a3a;
}

/* Button Styling */
.term-contact-container {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.term-contact-container h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffd700;
    margin-bottom: 20px;
}

.term-contact-button {
    background: linear-gradient(145deg, #ffd700, #ffa500);
    color: #1a1a1a;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.term-contact-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(255, 215, 0, 0.3);
}

/* ----------- Mobile Responsive Design (Phone View) ----------- */
@media (max-width: 768px) {
    .child-plans-container {
        padding: 7rem 1.5rem;
    }

    .child-plans-section {
        padding: 20px;
    }

    .child-plans-section h2 {
        font-size: 1.8rem;
        padding-bottom: 8px;
        margin-bottom: 15px;
    }

    .section-content {
        flex-direction: column;
        gap: 20px;
    }

    .section-img {
        width: 100%;
        max-width: 100%;
    }

    .child-plans-section p {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    /* Lists */
    .child-plans-section ul {
        margin-bottom: 15px;
    }

    .child-plans-section ul li {
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    .child-plans-section ul li::before {
        font-size: 1.2rem;
        margin-right: 8px;
    }

    /* Table Styling */
    .comparison-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 15px;
    }

    .comparison-table th,
    .comparison-table td {
        padding: 10px;
        font-size: 0.9rem;
    }

    .comparison-table th {
        font-size: 1rem;
    }

    /* Button Styling */
    .term-contact-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .term-contact-container h3 {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .term-contact-button {
        font-size: 1rem;
        padding: 12px 24px;
    }
}