.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease; /* Smooth fade-in effect */
  }
  
  .popup-content {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow for a floating effect */
    width: 85%; /* More responsive width */
    max-width: 1000px;
    text-align: left;
    overflow-y: auto;
    max-height: 90vh;
    animation: scaleUp 0.3s ease-out; /* Smooth scale-up animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  
  .popup-content h3 {
    margin-top: 0;
    font-size: 1.8em;
    color: #444; /* Slightly darker text for readability */
    text-align: center;
    margin-bottom: 40px;
    /* font-family: 'Arial', sans-serif; */
    letter-spacing: 1px;
  }
  
  h4 {
    margin-bottom: 12px;
    font-size: 1.4em;
    color: #f39c12; /* Gold color for heading */
    font-weight: bold;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1;
    min-width: 220px;
    max-width: 320px;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .column p {
    margin: 8px 0;
    color: #777;
    font-size: 1.1em;
    line-height: 1.5;
  }
  
  .libuttonclose {
    padding: 10px 40px;
    background-color: #3498db; /* Brighter blue */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 30px; /* Rounded corners for a modern look */
    transition: background-color 0.3s ease, transform 0.2s ease;
    align-self: center;
    margin-top: 30px;
    font-size: 1.1em;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  }
  
  .libuttonclose:hover {
    background-color: #2980b9; /* Darker blue for hover effect */
    transform: translateY(-3px); /* Subtle lift effect on hover */
  }
  
  .libuttonclose:focus {
    outline: none;
  }
  
  .libuttonclose:active {
    transform: translateY(0); /* Remove lift effect when clicked */
  }

  .pancardhref{
    color: #f39c12;
    text-decoration: none;
  }