.tax-strategies-container {
  display: flex;
  gap: 3rem;
  padding: 4rem 2rem;
  background-color: #000;
  font-family: 'Arial', sans-serif;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.image-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 40%;
}

.image-inner-wrapper {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.image-wrapper {
  border-radius: 15px;
  overflow: hidden;
  flex: 1;
  min-width: 200px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.image-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-wrapper:hover::after {
  opacity: 1;
}

.image-inner-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-section {
  text-align: center;
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem 2rem;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

.experience-section h2 {
  font-size: 2.5rem;
  margin: 0;
  color: #e7b32b;
}

.experience-section p {
  margin: 0;
  font-size: 1.2rem;
  color: #e7b32b;
}

.text-section {
  max-width: 600px;
  flex: 1;
  background-color: #fff;
  border-radius: 15px;
  padding: 2.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.text-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

.text-section h5 {
  font-size: 1rem;
  color: #e7b32b;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0.5rem;
}

.text-section h1 {
  font-size: 2.5rem;
  margin: 0.5rem 0 1rem;
  color: #000;
  line-height: 1.3;
}

.text-section p {
  color: #1a1a1a;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.checklist {
  list-style: none;
  padding: 0;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.checklist li {
  margin: 0.75rem 0;
  position: relative;
  padding-left: 1.75rem;
  font-size: 1.1rem;
}

.checklist li:before {
  content: "✔";
  color: #e7b32b;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

.learn-more {
  padding: 0.75rem 1.5rem;
  background-color: #e7b32b;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.learn-more:hover {
  background-color: #d4a025;
  transform: translateY(-3px);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .tax-strategies-container {
    padding: 3rem 1.5rem;
  }

  .image-section {
    max-width: 45%;
  }

  .text-section {
    max-width: 50%;
  }
}

@media (max-width: 1024px) {
  .image-section {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .image-inner-wrapper {
    flex-direction: row;
    gap: 1rem;
  }

  .image-wrapper {
    min-width: calc(50% - 0.75rem);
  }

  .text-section {
    max-width: 100%;
    padding: 2rem;
  }

  .text-section h1 {
    font-size: 2rem;
  }

  .text-section p {
    font-size: 1rem;
  }

  .checklist li {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .tax-strategies-container {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .image-section {
    flex-direction: column;
    gap: 1rem;
  }

  .image-inner-wrapper {
    flex-direction: row; /* Keep images in a row on mobile */
    gap: 1rem;
  }

  .image-wrapper {
    min-width: calc(50% - 0.5rem); /* Two images side by side */
    height: 150px; /* Fixed height for consistency */
  }

  .image-wrapper img {
    height: 100%; /* Ensure images fill the container */
  }

  .text-section {
    text-align: center;
  }

  .checklist li {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .tax-strategies-container {
    padding: 1.5rem 1rem;
  }

  .experience-section h2 {
    font-size: 2rem;
  }

  .experience-section p {
    font-size: 1rem;
  }

  .text-section h1 {
    font-size: 1.75rem;
  }

  .text-section p {
    font-size: 0.9rem;
  }

  .checklist li {
    font-size: 0.9rem;
  }

  .learn-more {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .image-wrapper {
    height: 120px; /* Smaller height for phones */
  }
}