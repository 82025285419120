.dashboard-popup-section {
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Align content in a column */
    min-height: 100vh; /* Make section take full height of the viewport */
    padding: 2rem;
    text-align: center;
    background-color: #1a1a1a;
  
    .dashboard-popup-title {
      font-size: 2rem;
      font-weight: bold;
      color: #e7b32b;
      margin-bottom: 0.5rem;
    }
  
    .dashboard-popup-subtitle {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 2rem;
    }
  
    .dashboard-popup-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
  
      .dashboard-popup-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s, box-shadow 0.3s;
        width: 250px;
        overflow: hidden;
  
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
  
        .dashboard-popup-card-inner {
          text-decoration: none;
          color: inherit;
  
          .dashboard-popup-box {
            padding: 1rem;
  
            .dashboard-popup-img-box {
              width: 100%;
              height: 150px;
              overflow: hidden;
              border-radius: 8px;
              margin-bottom: 1rem;
  
              .dashboard-popup-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
              }
            }
  
            .dashboard-popup-icon {
              text-align: center;
  
              .dashboard-popup-heading {
                font-size: 1.2rem;
                font-weight: bold;
                color: #333;
                margin: 0;
                transition: color 0.3s;
  
                &:hover {
                  color: #e7b32b;
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Responsive styling
  @media (max-width: 768px) {
    .dashboard-popup-container {
      flex-direction: column;
      align-items: center;
    }
  
    .dashboard-popup-card {
      width: 90%;
    }
  }
  