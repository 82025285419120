/* Mission Container */
.mission-container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #ffffff;
  overflow: hidden;
  padding: 6rem 4rem; /* Increased padding for more space */
  font-family: 'Poppins', sans-serif;
}

/* Mission Inner Container */
.mission-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem; /* Increased gap for better spacing */
  padding: 4rem;
  max-width: 1400px; /* Slightly wider for desktop */
  margin: 0 auto;
}

/* Left Section */
.mission-left-section {
  flex: 1;
  min-width: 400px; /* Slightly wider for desktop */
}

/* Image Container */
.mission-image-container {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.4); /* Stronger shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 500px; /* Taller for desktop */
}

.mission-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)); /* Stronger gradient */
  border-radius: 25px;
  z-index: 1;
}

.mission-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  transition: transform 0.3s ease;
}

.mission-image-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.6); /* Stronger shadow on hover */
}

.mission-image-container:hover .mission-main-image {
  transform: scale(1.05);
}

.mission-image-container:hover::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)); /* Darker gradient on hover */
}

/* Quote Overlay */
.mission-quote-overlay {
  position: absolute;
  bottom: 30px; /* Adjusted position */
  left: 30px; /* Adjusted position */
  right: 30px; /* Adjusted position */
  background: linear-gradient(145deg, rgba(231, 179, 43, 0.9), rgba(231, 179, 43, 0.7));
  padding: 1.5rem 2rem; /* Increased padding */
  color: #000;
  border-radius: 15px; /* Slightly larger border radius */
  font-size: 1.2rem; /* Larger font size */
  font-weight: 600;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Stronger shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 2;
}

.mission-quote-overlay:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5); /* Stronger shadow on hover */
}

.mission-quote-icon {
  font-size: 1.8rem; /* Larger icon */
  font-weight: bold;
  margin-right: 0.75rem; /* Adjusted spacing */
}

/* Right Section */
.mission-right-section {
  flex: 2;
  padding: 2rem; /* Increased padding */
}

.mission-right-section h4 {
  color: #e7b32b;
  font-weight: 600;
  font-size: 1.2rem; /* Larger font size */
  text-transform: uppercase;
  letter-spacing: 2.5px; /* Increased letter spacing */
  margin-bottom: 1.5rem; /* Increased margin */
}

.mission-right-section h2 {
  font-size: 3rem; /* Larger font size */
  margin-bottom: 2rem; /* Increased margin */
  color: #fff;
  line-height: 1.4; /* Adjusted line height */
  font-weight: 700;
  letter-spacing: -0.5px; /* Slight letter spacing */
}

.mission-right-section p {
  margin-bottom: 2.5rem; /* Increased margin */
  line-height: 1.9; /* Adjusted line height */
  color: #f2f2f2;
  font-size: 1.2rem; /* Larger font size */
  max-width: 800px; /* Limit width for better readability */
}

/* Skills Container */
.mission-skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two-column grid */
  gap: 2rem; /* Increased gap */
}

.mission-skill {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* Increased gap */
}

.mission-skill span {
  font-size: 1.1rem; /* Larger font size */
  font-weight: 500;
  color: #fff;
}

.mission-progress-bar {
  width: 100%;
  height: 12px; /* Thicker progress bar */
  background: #444;
  border-radius: 10px; /* Larger border radius */
  overflow: hidden;
  position: relative;
}

.mission-progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #e7b32b, #d4a025);
  border-radius: 10px; /* Larger border radius */
  transition: width 0.5s ease;
}

.mission-percentage {
  font-size: 1rem; /* Larger font size */
  font-weight: 500;
  color: #e7b32b;
  margin-left: auto;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .mission-inner {
    padding: 3rem;
  }

  .mission-right-section h2 {
    font-size: 2.5rem;
  }

  .mission-right-section p {
    font-size: 1.1rem;
  }
}

@media (max-width: 1024px) {
  .mission-inner {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }

  .mission-left-section {
    min-width: 100%;
  }

  .mission-right-section {
    padding: 0;
  }

  .mission-right-section h2 {
    font-size: 2rem;
  }

  .mission-right-section p {
    font-size: 1rem;
  }

  .mission-image-container {
    height: 400px; /* Adjusted height for tablets */
  }
}

@media (max-width: 768px) {
  .mission-container {
    padding: 4rem 2rem;
  }

  .mission-inner {
    padding: 1rem;
  }

  .mission-right-section h2 {
    font-size: 1.75rem;
  }

  .mission-right-section p {
    font-size: 0.95rem;
  }

  .mission-quote-overlay {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }

  .mission-quote-icon {
    font-size: 1.5rem;
  }

  .mission-image-container {
    height: 350px; /* Adjusted height for smaller screens */
  }

  .mission-skills-container {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }
}

@media (max-width: 480px) {
  .mission-right-section h2 {
    font-size: 1.5rem;
  }

  .mission-right-section p {
    font-size: 0.9rem;
  }

  .mission-quote-overlay {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }

  .mission-quote-icon {
    font-size: 1.2rem;
  }

  .mission-image-container {
    height: 300px; /* Adjusted height for mobile */
  }
}