body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.container-xl {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

.card {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
}

.card-header {
  background-color: #007bff;
  color: #fff;
  padding: 15px 20px;
  font-size: 1.25rem;
  border-bottom: none;
}

.card-body {
  padding: 20px;
}

.form-control {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-select {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  transition: all 0.3s ease;
}

.form-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.img-account-profile {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.imagecircle {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}

.text-center.mb-4 {
  text-align: center;
  margin-bottom: 20px;
}

.small.font-italic.text-muted.mb-4 {
  font-size: 0.875rem;
  font-style: italic;
  color: #6c757d;
  margin-bottom: 20px;
}

.editprohead {
  font-family: "Georgia", serif;
  color: #fff;
  padding: 10px 20px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.gx-3.mb-3 > .col-md-6 {
  margin-bottom: 15px;
}

.editproclass {
  text-align: center;
  justify-content: center;
}

input[type="file"] {
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

input[type="file"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* EditProfile.scss */

.container-xl {
  padding: 20px;
}

.card {
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #007bff; /* Bootstrap primary color for header */
  color: white;
  padding: 10px 15px;
}

.card-body {
  padding: 20px;
}

.btn {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker shade of primary color on hover */
}

.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.edit-popup-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Box shadow for effect */
  max-width: 400px; /* Adjust as per your design */
  width: 100%;
}

.edit-popup h2 {
  margin-bottom: 10px;
}

.edit-popup p {
  margin-bottom: 20px;
}

.edit-popup button {
  background-color: #007bff; /* Bootstrap primary button color */
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.edit-popup button:hover {
  background-color: #0056b3; /* Darker shade of primary color on hover */
}

