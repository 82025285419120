@import url("https://fonts.googleapis.com/css2?family=Moderustic:wght@400&family=Playfair+Display:wght@600&display=swap");

@mixin mQ($px) {
  @media screen and (min-width: $px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; // Ensures consistent box sizing across elements
}

body {
  font-family: "Moderustic", sans-serif;
}

.about_home_section {
  padding-block: min(20vh, 3.5rem);
  padding: 3.5rem;
  margin-inline: auto;
  color: #111;
  background-color: #1a1a1a;

  .about_home_h2 {
    color: #e7b32b;
    letter-spacing: 0.025em;
    font-size: 1.6rem;
    line-height: 1.1;
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    text-align: center;

    @include mQ(53rem) {
      font-size: 3.2rem;
    }
  }

  .about_home_para {
    font-size: 1.1rem;
    color: #696969;
    margin-top: 0.8em;
    text-align: center;
  }

  .about_home_container {
    margin-top: 4em;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr; // Default to a single column layout

    @include mQ(40rem) {
      grid-template-columns: repeat(2, 1fr); // 2 columns on medium screens
    }

    @include mQ(53rem) {
      grid-template-columns: repeat(4, 1fr); // 4 columns on larger screens
    }

    .about_home_card {
      padding: 0.625rem;
      background: #f1efef;
      border-radius: 1.2rem;
      overflow: hidden;
      cursor: pointer;

      @include mQ(53rem) {
        border-radius: 2rem;
      }
    }

    .about_home_card-inner {
      position: relative;
      width: 100%;
      height: 9rem;
      background: var(--clr);
      border-bottom-right-radius: 0;

      .about_home_box {
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 1.6rem;
        overflow: hidden;

        .about_home_imgBox {
          position: absolute;
          inset: 0;

          .about_home_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;

            @include mQ(53rem) {
              border-radius: 1.6rem;
            }
          }
        }

        .about_home_icon {
          position: absolute;
          bottom: -0.650rem;
          right: 0;
          width: 100%;
          height: 3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          pointer-events: none;

          @include mQ(53rem) {
            width: 13rem;
            border-top-left-radius: 1rem;
            right: -0.650rem;
          }

          .about_home_h3 {
            margin-left: 0.3em;
            text-transform: uppercase;
            font-size: 0.9rem;
            color: #000000;
            font-weight: 400;

            @include mQ(53rem) {
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .about_home_h2 {
    font-size: 1.2rem !important;
    text-align: justify !important;
  }

  .about_home_para {
    font-size: 0.9rem !important;
    text-align: justify !important;
  }

  .about_home_container {
    gap: 1.5rem;
  }

  .about_home_card {
    padding: 0.5rem;
  }

  .about_home_card-inner {
    height: 8rem;
  }

  .about_home_icon {
    bottom: -0.5rem;
  }

  .about_home_h3 {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .about_home_h2 {
    font-size: 1.8rem;
  }

  .about_home_para {
    font-size: 0.9rem;
  }

  .about_home_container {
    gap: 1rem;
    grid-template-columns: 1fr;  /* Stacks the cards on small screens */
  }

  .about_home_card {
    padding: 0.25rem;
  }

  .about_home_card-inner {
    height: 7rem;
  }

  .about_home_h3 {
    font-size: 0.75rem;
  }
}
