

.section-title {
    font-weight: 600;
    color: var(--blue);
    margin-bottom: 15px;
    border-bottom: 2px solid var(--blue);
    padding-bottom: 5px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pms_login_entry-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Two columns for the form fields */
    gap: 20px; /* Add space between rows and columns */
}

.pms_login_entry-row {
    display: flex;
    flex-direction: column; /* Align label and input vertically */
    width: 100%; /* Ensure rows span full width of their container */
}

.pms_login_entry-row label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.pms_login_entry-row input[type="text"],
.pms_login_entry-row input[type="password"],
.pms_login_entry-row select {
    width: 100%; /* Ensure inputs take the full width of their parent */
    padding: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white);
    box-sizing: border-box; /* Include padding in width calculation */
}

.pms_login_entry-row input[type="text"]:focus,
.pms_login_entry-row input[type="password"]:focus,
.pms_login_entry-row select:focus {
    border-color: var(--blue);
}

.pms_login_entry-row button {
    grid-column: span 2; /* Make button span across two columns */
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: var(--white);
    background: var(--gradient);
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.pms_login_entry-row button:hover {
    background: linear-gradient(135deg, #5b76e3, #956fd3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}