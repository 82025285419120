/* Header Section */
.home_insurance-header-section {
    text-align: center;
    padding: 3rem;
    background-color: #000000;
}

.home_insurance-main-title {
    font-size: 48px;
    font-weight: bold;
    color: #e7b32b;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.home_insurance-subtitle {
    font-size: 20px;
    color: #7f8c8d;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Introduction Section */
.home_insurance-intro-section {
    margin-bottom: 60px;
}

.home_insurance-intro-text {
    font-size: 18px;
    color: #fff;
    line-height: 1.8;
    text-align: center;
    padding: 0 20px;
}





.carousell-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;  */
    padding-top: 40px;
    background-color: #000000; /* Optional: background for visual appeal */
}

.home_insurance-section-title{
    font-size: 32px;
    color: #e7b32b;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;
}

.carousell {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    perspective: 100rem;
}

.carousell__cards {
    position: relative;
    width: 20rem;
    height: 25rem; /* Set a consistent height for the cards */
    transform-style: preserve-3d;
    transform: translateZ(-25rem);
    transition: transform 1s ease-in-out;
}

.carousell__card {
    width: 100%;
    height: 80%;
    position: absolute;
    background-color: #000;
    border: 2px solid white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure content starts from the top */
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden; /* Prevents content from spilling outside */
    text-decoration: none;
}

.carousell__img-container {
    width: 100%;
    height: 50%; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures the image doesn’t spill outside */
    border-radius: 24px 24px 0 0; /* Matches card’s top rounded corners */
    background-color: #1a1a1a; /* Optional: Background color for the image container */
}

.carousell__img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container while maintaining aspect ratio */
}


.carousell__content {
    flex-grow: 1; /* Push content to take up the remaining space */
    text-align: center;
    margin-top: 10px;
    color: #fff; /* Ensure text is visible on dark backgrounds */
    z-index: 1; /* Ensure text is on top of the image */
}

.carousell__title {
    font-size: 1.5rem;
    font-weight: bold;
    color: gold;
    margin: 10px 0; /* Add uniform margin around the title */
}

.carousell__para {
    font-size: 1rem;
    color: white;
    margin: 10px 0; /* Add uniform margin around the paragraph */
}

/* Transform for each card */
.carousell__card:nth-child(1) {
    transform: rotateY(0deg) translateZ(25rem);
}

.carousell__card:nth-child(2) {
    transform: rotateY(45deg) translateZ(25rem);
}

.carousell__card:nth-child(3) {
    transform: rotateY(90deg) translateZ(25rem);
}

.carousell__card:nth-child(4) {
    transform: rotateY(135deg) translateZ(25rem);
}

.carousell__card:nth-child(5) {
    transform: rotateY(180deg) translateZ(25rem);
}

.carousell__card:nth-child(6) {
    transform: rotateY(225deg) translateZ(25rem);
}

.carousell__card:nth-child(7) {
    transform: rotateY(270deg) translateZ(25rem);
}

.carousell__card:nth-child(8) {
    transform: rotateY(315deg) translateZ(25rem);
}


/* Benefits Section */
.home_insurance-benefits-section {
    padding-top: 10px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    color: #f0f0f0;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.home_insurance-benefits-inner-section {
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    align-items: flex-start;
    border: 2px solid white;
    padding: 40px;
    border-radius: 24px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
}

/* Improved Image UI */
.home_insurance-benefits-section img {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 16px;
    margin-bottom: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    object-fit: cover;
}

/* Hover effect for the image */
.home_insurance-benefits-section img:hover {
    transform: scale(1.05);
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.5);
}

/* New Benefits List */
.home_insurance-benefits-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
    color: #d1d5db;
    font-size: 14px;
    margin: 0;
}

.home_insurance-benefits-list li {
    padding-left: 30px;
    margin-bottom: 20px;
    transition: color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for list items */
.home_insurance-benefits-list li:hover {
    color: #e7b32b;
    transform: translateX(8px);
}

.home_insurance-benefits-list li:last-child {
    color: red;
}


/* Call to Action Button */
.home_insurance-cta-section {
    text-align: left;
    margin-top: 40px;
}

.home_insurance-cta-button {
    background: linear-gradient(135deg, #e7b32b, #e7b32b);
    color: #ffffff;
    font-size: 22px;
    padding: 20px 60px;
    text-decoration: none;
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
}

/* Hover effect for the CTA button */
.home_insurance-cta-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */

/* For screens smaller than 768px */
@media (max-width: 768px) {
    .home_insurance-header-section {
        padding: 2rem;
    }

    .home_insurance-main-title {
        font-size: 36px;
    }

    .home_insurance-subtitle {
        font-size: 18px;
    }

    .home_insurance-intro-text {
        font-size: 16px;
    }

    .home_insurance-features,
    .home_insurance-types {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Flexibility for smaller screens */
    }

    .home_insurance-carousel {
        gap: 15px; /* Adjust gap for smaller screens */
        padding: 10px;
    }

    .home_insurance-cta-button {
        font-size: 18px;
        padding: 15px 30px;
    }

    .home_insurance-benefits-section img {
        max-width: 300px;
    }

    .home_insurance-benefits-section {
        padding: 20px 20px;
        text-align: center;
        color: #f0f0f0;
        border-radius: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .home_insurance-benefits-inner-section {
        flex-direction: column;
        gap: 40px;
    }

    .home_insurance-benefits-list li {
        padding-left: 0px;
        margin-bottom: 20px;
        transition: color 0.3s ease, transform 0.3s ease;
        text-align: justify;
    }

    .home_insurance-cta-section {
        text-align: center;
        margin-top: 40px;
    }
}

/* For screens smaller than 480px */
@media (max-width: 480px) {
    .home_insurance-main-title {
        font-size: 30px;
    }

    .home_insurance-subtitle {
        font-size: 16px;
    }

    .home_insurance-feature-card,
    .home_insurance-type-card {
        padding: 25px;
    }

    .home_insurance-benefits-list {
        font-size: 14px;
    }

    .home_insurance-cta-button {
        font-size: 16px;
        padding: 12px 25px;
    }

    .home_insurance-benefits-inner-section {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        gap: 40px;
    }

    .home_insurance-benefits-section img {
        max-width: 100%;
    }
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) {

    .home_insurance-section-title{
       display: none;
    }

    .carousell-container {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .carousell {
        height: 50vh; /* Adjust height to fit screen */
        width: 100%;
        display: flex;
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        perspective: 1000px; /* Adjust perspective for better 3D effect */
    }

    .carousell__cards {
        position: relative;
        width: 100%; /* Stretch to take full container width */
        height: 18rem; /* Adjust height */
        transform-style: preserve-3d;
        transform: translateX(-50%) translateZ(-10rem); /* Center cards horizontally and in depth */
        transition: transform 0.8s ease-in-out;
    }

    .carousell__card {
        /* width: 12rem;  */
        height: 16rem; /* Adjust card height for smaller screens */
        position: absolute;
        background-color: #000;
        border: 1px solid white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .carousell__img-container {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 16px 16px 0 0;
        background-color: #1a1a1a;
    }

    .carousell__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .carousell__title {
        font-size: 1rem;
        font-weight: bold;
        color: gold;
        margin: 10px 0;
    }

    .carousell__para {
        font-size: 0.8rem;
        color: white;
        text-align: center;
    }

    /* Transform for each card */
    .carousell__card:nth-child(1) {
        transform: rotateY(0deg) translateZ(12rem); /* Center the first card */
    }

    .carousell__card:nth-child(2) {
        transform: rotateY(90deg) translateZ(12rem);
    }

    .carousell__card:nth-child(3) {
        transform: rotateY(180deg) translateZ(12rem);
    }

    .carousell__card:nth-child(4) {
        transform: rotateY(270deg) translateZ(12rem);
    }
}


