/* Default styles (Desktop / Larger screens) */
.tab-cloneable {
  padding: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  font-size: 1.1vw;
  background-color: black;
}

.tab-container {
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 2.5em;
  display: flex;
}

.tab-layout-container {
  width: 100%;
  max-width: 40em;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-top: 1em;
  padding-bottom: 2em;
}

.tab-container-bottom, .tab-container-top {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.tab-layout-col {
  width: 50%;
}

.tab-content-wrap {
  width: 100%;
  min-width: 24em;
  position: relative;
}

.content-button__bg {
  z-index: -1;
  background-color: #efeeec;
  border-radius: .25em;
  position: absolute;
  inset: 0%;
}

.tab-content-p {
  margin: 0;
  font-size: 1.25em;
  line-height: 1.4;
}

.tab-button__bg {
  z-index: 0;
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .25em;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.tab-content-item {
  z-index: 1;
  grid-column-gap: 1.25em;
  grid-row-gap: 1.25em;
  visibility: hidden;
  flex-flow: column;
  display: flex;
  position: absolute;
  inset: auto 0% 0%;
  color: white;
}

.tab-content-item.active {
  visibility: visible;
}

.tab-layout {
  z-index: 1;
  grid-row-gap: 3em;
  flex-flow: wrap;
  width: 100%;
  min-height: 37em;
  display: flex;
  position: relative;
}

.tab-filter-bar {
  background-color: #efeeec0f;
  border: 1px solid #efeeec14;
  border-radius: .5em;
  padding: .5em;
  display: flex;
}

.tab-filter-button {
  background-color: #0000;
  border: 1px solid #efeeec00;
  padding: 1.125em 1.0em;
  transition: border-color .2s;
  position: relative;
  margin: 2px;
}

.tab-filter-button.active {
  border-color: #efeeec4d;
  border-radius: .25em;
}

.tab-filter-button__p {
  z-index: 1;
  font-size: 0.9em;
  position: relative;
  color: white;
}

.tab-visual-wrap {
  border-radius: .5em;
  width: 100%;
  height: 42em;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
}

.tab-visual-item {
  visibility: hidden;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.tab-visual-item.active {
  visibility: visible;
}

.tab-image {
  object-fit: cover;
  border-radius: .5em;
  width: 100%;
  max-width: none;
  height: 100%;
}

.tab-content__heading {
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1;
}

.tab-layout-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: 500;
  line-height: 1;
  color: white;
}

.tab-content__button {
  color: #131313;
  justify-content: center;
  align-items: center;
  height: 4em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-decoration: none;
  display: flex;
  position: relative;
}

/* Mobile View (Phone View) */
@media (max-width: 768px) {
  .tab-cloneable {
    padding: 1rem;
    font-size: 4vw;
  }

  .tab-container {
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    padding-right: 1.5em;
  }

  .tab-layout-container {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .tab-layout {
    flex-flow: column;
    min-height: auto;
  }

  .tab-layout-col {
    width: 100%;
  }

  .tab-content-p {
    font-size: 1em;
  }

  .tab-visual-wrap {
    height: 30em;
  }

  .tab-content__heading {
    font-size: 1.5em;
  }

  .tab-layout-heading {
    font-size: 2em;
  }

  .tab-content__button {
    height: 3em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .tab-filter-bar {
    background-color: #efeeec0f;
    border: 1px solid #efeeec14;
    border-radius: .5em;
    padding: .5em;
    display: flex;
    margin-bottom: 85px;
  }
  

  .tab-filter-button {
    padding: 0.75em 0.5em;
  }

  .tab-filter-button__p {
    font-size: 0.8em;
  }

  .tab-visual-wrap {
    max-height: 60vh;
  }
}
