.our-services {
  background-color: black;
  padding-top: 60px;
  padding-bottom: 20px;
}

/* About Us Section */
.about-us {
  color: #e7b32b;
  font-weight: bold;
}

/* About Company Text */
.about-company {
  font-weight: 700;
  line-height: 1.5;
  color: white;
}

/* Highlighted Text */
.ser-highlighted-text {
  color: #e7b32b;
}

/* About Description */
.about-description {
  line-height: 1.8;
  font-size: 1rem;
  color: #6c757d;
  text-align: justify;
}

/* Images with Hover Effect */
img {
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

// img:hover {
//   transform: scale(1.05);
//   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
// }

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .our-services {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .about-us {
    font-size: 1.5rem; /* Reduced font size for mobile */
    text-align: center !important;
    display: block;
  }

  .about-company {
    font-size: 1.2rem; /* Slightly smaller font size for mobile */
    text-align: center;
  }

  .ser-highlighted-text {
    font-size: 1.2rem; /* Increased font size slightly for emphasis */
  }

  .about-description {
    font-size: 0.95rem; /* Reduced font size for smaller screens */
    line-height: 1.6; /* Adjusted line-height for readability */
    padding: 20px;
  }

  /* Image responsiveness */
  img {
    width: 100%; /* Make images responsive to screen size */
    max-width: 100%;
    height: auto;
  }

  .second-img{
    display: none;
  }
}

/* Small Phone View (480px and below) */
@media (max-width: 480px) {
  .about-us {
    font-size: 1.2rem; /* Further reduced font size */
    text-align: center !important;
  }

  .about-company {
    font-size: 1rem; /* Smaller font size for tiny screens */
  }

  .ser-highlighted-text {
    font-size: 1rem; /* Adjusted highlighted text size */
  }

  .about-description {
    font-size: 0.9rem; /* Smaller font size for better readability */
    line-height: 1.5; /* Slightly tighter line-height */
  }

  /* Image responsiveness */
  img {
    width: 100%; /* Full width for images on small screens */
    height: auto;
  }
}
