.service-popup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  padding: 2rem;
  text-align: center;
  color: #111;
  background-color: black;

  .service-popup-title {
    font-family: "Playfair Display", serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: gold;
  }

  .service-popup-subtitle {
    font-size: 1.2rem;
    color: #696969;
    margin-bottom: 2rem;
  }

  .service-popup-container {
    display: grid;
    gap: 2rem;
    max-width: 80%;
    width: 100%;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    .service-popup-card {
      background: #f1efef;
      border-radius: 1rem;
      padding: 1rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      }

      .service-popup-card-inner {
        position: relative;
        overflow: hidden;
        border-radius: 0.8rem;
        background: #fff;
        text-decoration: none;

        .service-popup-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .service-popup-img-box {
            width: 100%;
            height: 10rem;
            overflow: hidden;
            border-radius: 0.8rem;
            margin-bottom: 1rem;

            .service-popup-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .service-popup-icon {
            .service-popup-heading {
              font-size: 1rem;
              color: #333;
              font-weight: 500;
              text-transform: uppercase;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

/* Phone view specific styles */
@media screen and (max-width: 480px) {
  .service-popup-section {
    padding: 4rem;
    height: auto; /* Allow scrolling for smaller screens */

    .service-popup-title {
      font-size: 1.6rem; /* Smaller title font size */
    }

    .service-popup-subtitle {
      font-size: 1rem; /* Adjust subtitle for smaller screens */
      margin-bottom: 1rem;
    }

    .service-popup-container {
      gap: 1rem; /* Reduce gap between cards */
      max-width: 100%;
      grid-template-columns: 1fr; /* Single column layout */

      .service-popup-card {
        padding: 0.8rem; /* Reduce padding for compactness */
      }

      .service-popup-card-inner {
        .service-popup-box {
          .service-popup-img-box {
            height: 8rem; /* Reduce image height */
            margin-bottom: 0.5rem;
          }

          .service-popup-heading {
            font-size: 0.9rem; /* Slightly smaller heading font */
          }
        }
      }
    }
  }
}
