/* Notification.css */
.notification-menu {
    position: absolute;
    right: 95px;
    top: 100px;
    width: 300px;
    background-color: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px;
    z-index: 1001; /* Ensure it is above the overlay */
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.notification-header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.notification-header button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s;
}

.notification-header button:hover {
    color: #333;
}

.notification-content {
    max-height: 250px;
    overflow-y: auto;
    padding-right: 10px;
}

.notification-item {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
    color: #555;
    font-size: 14px;
}

.notification-item:last-child {
    border-bottom: none;
}

.clear-button {
    margin-top: 20px;
    width: 100%;
    background-color: #f44336;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.clear-button:hover {
    background-color: #d32f2f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Ensure it is below the notification menu */
}


@media (max-width: 768px) {
 .notification-menu {
    position: absolute;
    right: 43px;
    top: 160px;
    width: 300px;
    background-color: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px;
    z-index: 1001;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
}

}