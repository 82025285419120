* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashoard-main-div {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enables vertical scrolling */
}

/* -------------- MAIN CONTENT -------------- */
.main-content {
  flex: 1; /* Takes the remaining space in the flex container */
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #000000, #333333); /* Black gradient */
}

/* -------------- TOP CONTAINER -------------- */
.top-container {
  flex: 0 0 auto; /* Prevents the top-container from stretching */
  padding: 1rem;
  background-color: black;
  border-bottom: 1px solid gold;
}

.bottom-container {
  flex: 1 1 auto; /* Allows the bottom-container to stretch */
  padding: 1rem;
  overflow-y: auto; /* Ensures the bottom-container can be scrolled vertically */
  background: linear-gradient(to bottom, #000000, #333333); /* Black gradient */
}

/* -------------- SEARCH -------------- */
.search {
  display: flex;
  align-items: center;
  padding: 11px 0 11px 24px;
  max-width: 492px;
  width: 100%;
  border: 1px solid gold;
  border-radius: 70px;
  gap: 26px;
  background: #000000;
  opacity: 0.8;
}

.search__input {
  border: none;
  outline: none;
  width: calc(100% - 78px);
}

/* -------------- USER NAV -------------- */
.user-nav {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-info .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
}

.user-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* -------------- NOTIFICATION -------------- */
.notification {
  border: 1px solid none;
  background-color: white;
  position: relative;
  margin-right: 20px;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.notification::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  top: -2px;
  right: 3px;
}

/* -------------- BOTTOM CONTAINER -------------- */
.bottom-container {
  display: flex;
  gap: 24px;
  padding: 32px;
  background-color: #000000;
}

.box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px; /*fix later*/
}

.bottom-container__left {
  width: 70%;
}

.bottom-container__right {
  width: 30%;
}

.spending-statistics {
  max-height: 338px;
}










/* -------------- BAR CHART -------------- */
/* Bar chart container */
.bar-chart {
  flex-grow: 1;  /* Allow the chart to take up available space */
  max-height: 300px;  /* Limit the chart's height */
  width: 100%;  /* Ensure it takes up full width of the container */
  display: flex;
  justify-content: center;
  align-items: center;  /* Center the chart */
}

/* -------------- HEADER -------------- */
.header-container {
  color: #1a202c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  font-weight: 600;
  letter-spacing: -2%;
}

.section-header {
  line-height: 24px;
  font-size: 18px;
}

.header-container span {
  line-height: 21px;
  font-size: 14px;
  color: gold;
}

.year-selector {
  display: flex;
  align-items: center;
  gap: 24px;
}

.total-box {
  display: inline-block;
  width: 100%;
}

.total-box__left {
  border-right: 1px solid #f0f0f0;
  flex: 1;
}

.total-box__right {
  flex: 1;
}

.up-arrow,
.total-box__left {
  margin-right: 24px;
}

.price {
  line-height: 150%;
  margin-bottom: 14px;
}

.price-currency {
  line-height: 21px;
  font-size: 14px;
  font-weight: 600;
  color: #90a3bf;
  margin-left: 8px;
}

.total-box p,
.box p {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #90a3bf;
}

.percentage-increase {
  color: #7fb519;
}

.percentage-decrease {
  color: #ff4423;
}

.date-selector {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c3d4e9;
  border-radius: 6px;
}

.date-selector span {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-left: 8px;
}

.transaction-box {
  border: 1px solid gold;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;

  .header-container {
    margin-bottom: 20px;
  }

  .transaction-history {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
}

/* -------------- TABLE -------------- */
tr > th {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #90a3bf;
  padding-bottom: 24px;
}

tr > td {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -2%;
  padding: 8px 0;
  color: #000;
}

// tr > td:first-child {
//   display: flex;
//   align-items: center;
//   gap: 9px;
//   text-align: left;
// }

.status {
  margin-right: 8px;
}

/* -------------- BUTTON -------------- */
.button-box {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
}

.dashbord-btn {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.btn-purple {
  background-color: #7c5cfc;
  color: white;
}

.btn-white {
  background-color: white;
  color: black;
  border: 2px solid #7c5cfc;
}













/* -------------- PIE CHART -------------- */

.spending-box {
  position: relative;
  background-color: #000000;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 20px;
  // min-height: 440px;
  min-height: 536px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid gold;
}

.spending-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.overall-spending {
  position: relative;
  text-align: center;
  margin: 0 auto; /* Center horizontally */
  top: 35%; /* Adjust this based on your layout needs */
  transform: translateY(-412%); /* Vertically center the element */
  width: 100%; /* Make it responsive */
  max-width: 600px; /* Optional: Set a max-width for better responsiveness */
}

.overall-spending > h4 {
  color: gold;
  font-weight: 500;
  font-size: 15px;
  // line-height: 150%;
  // margin-bottom: 10px;
}

.overall-spending > span {
  color: gold;
  font-weight: 600;
  font-size: 18px;
}

.pie-chart__labels {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pie-chart__labels-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

.pie-chart__labels-item:hover {
  background-color: #b6b6dc;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Slightly increased shadow on hover */
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
}

.label__color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform 0.3s; /* Add transition for smooth movement */
}

.amount {
  font-size: 14px;
  color: #555;
  font-weight: 600;
}

.pie-chart {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 9rem;
}

.pie-chart:hover .label__color {
  transform: translateY(-5px); /* Move the label color upwards */
}

.chart-container {
  height: 900px; /* You can adjust this height as needed */
  width: 100%;
}
/* Box container */
.box-size {
  height: auto;  /* Allow box to grow or shrink based on content */
  min-height: 400px;  /* Set a minimum height */
  margin-bottom: 1rem;
  background-color: #000;
  border: 1px solid gold;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;  /* Add padding to the content */
  box-sizing: border-box;
  gap: 1rem;  /* Space between header and chart */
}

.cricle_body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.circle {
  position: relative;
  width: 50px;
  height: 100px;
}

.circle svg {
  fill: none;
  stroke: #8a3ab9;
  stroke-linecap: round;
  stroke-width: 3;
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: stroke-draw 6s ease-out infinite alternate;
}

.circle img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  border-radius: 50%;
}

@keyframes stroke-draw {
  from {
    stroke: #8a3ab9;
    stroke-dasharray: 1;
  }
  to {
    stroke: #cd486b;
    transform: rotate(180deg);
    stroke-dasharray: 8;
  }
}

.topupbtn {
  text-decoration: none;
  color: #ffffff;
}

.transferbtn {
  text-decoration: none;
  color: #333;
}








/* FundInvested.css */
.bottom-center {
  padding: 32px;
  background: linear-gradient(to bottom, #313131, #333333); /* Black gradient */
  margin-top: -2.5rem;
}

.transaction-box {
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  padding: 20px;
  margin: 20px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.transaction-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header section */
.header-container {
  border-bottom: 1px solid #ffd700; /* Gold border */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.section-header {
  color: #ffd700; /* Gold text */
  font-size: 24px;
  font-weight: 600;
}

/* Table styles */
.transaction-history {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Prevent overflow */
}

.transaction-history th,
.transaction-history td {
  padding: 12px;
  text-align: center;
  font-size: 14px;
  color: #ffd700; /* Gold text */
  height: 50px; /* Fixed height for table rows */
  overflow: hidden; /* Prevent content from overflowing */
}

.transaction-history th {
  background: #000000; /* Black background */
  font-weight: 700;
  color: #ffd700; /* Gold text */
}

.transaction-history tbody tr:nth-child(even) {
  background: #1a1a1a; /* Darker black for contrast */
}

.transaction-history tbody tr:hover {
  background: #333333; /* Slightly lighter black on hover */
}

.transaction-history .green {
  color: #32cd32; /* Bright green for positive numbers */
  font-weight: bold;
}

.transaction-history .red {
  color: #e74c3c; /* Red for negative numbers */
  font-weight: bold;
}

.transaction-history th svg {
  margin-left: 5px;
  vertical-align: middle;
  fill: currentColor;
  transition: transform 0.3s ease;
}

.transaction-history th:hover svg {
  transform: translateY(-2px);
}

.transaction-history th:hover {
  background-color: #ffd700; /* Gold on hover */
  color: #000000; /* Black text on hover */
  transform: translateY(-2px);
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.3); /* Gold shadow */
}










// -------------- investment ----------------------- //

.investment-overview {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust the gap between the boxes as needed */
}

.total-box {
  flex: 1;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  padding: 20px; /* Adjusted padding from box-size and total-box */
  margin: 20px 0;
  border: 1px solid gold; /* From box-size */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 1rem; /* From box-size */
}

.total-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gold;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.section-header {
  color: gold;
  font-size: 24px;
  font-weight: 600;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-size: 36px;
  color: gold;
  margin-right: 10px;
  text-align: center;
}

.overall-growth {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.percentage-increase {
  font-size: 20px;
  color: #006400;
  font-weight: bold;
  margin-right: 5px;
}

.up-arrow {
  transition: transform 0.3s ease;
}

.total-box:hover .up-arrow {
  transform: translateY(-2px);
}

.bottom-container__center{
  width: 100%;
}

.pie-chart__labels {
  max-height: 285px; /* Adjust this to the height you expect for 5 items */
  overflow-y: auto;
  padding-right: 10px; /* Space for scrollbar */
}

.pie-chart__labels-item {
  height: 50px; /* Adjust this to the height of your items */
  padding: 5px 0; /* Add some padding for better spacing */
}

.stockmoney{
  margin-right: 10px;
}








// --------------------------------header------------------------ //

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.search {
  display: flex;
  align-items: center;
  background-color: #000000;
  border-radius: 8px;
  padding: 8px 12px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search:hover {
  background-color: #000000;
}

.search__icon {
  fill: gold;
  width: 22px;
  height: 22px;
  margin-right: 8px;
  transition: fill 0.3s ease;
}

.search:hover .search__icon {
  fill: black;
}

.search__input {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  color: gold;
}

.search__input::placeholder {
  color: gold;
}

.search__input:focus {
  color: gold;
}

.user-nav {
  display: flex;
  align-items: center;
  // gap: 5px;
}

.notification__icon {
  fill: #292d32;
  width: 24px;
  height: 24px;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.circle_body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 16px;
  color: gold;
  margin-left: 10px;
}

.user-name:hover {
  text-decoration: none;
}


.positive {
  color: green; /* Or any color you prefer for positive growth */
}

.negative {
  color: red; /* Or any color you prefer for negative growth */
}


@media (max-width: 1300px) {
  .bottom-container {
    flex-direction: column;
    height: auto;
  }

  .bottom-container__left,
  .bottom-container__right {
    width: 100%;
  }

  .dashbord-btn {
    width: 45%;
  }
}

@media (max-width: 900px) {
  .top-container {
    gap: 24px;
  }
  .total-box {
    flex-direction: column;
  }

  .total-box__left {
    margin-right: 0;
    border-right: none;
    border-bottom: 1px solid gold;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .up-arrow {
    margin-right: 0;
  }
}

@media (max-width: 780px) {
  .top-container {
    flex-direction: column;
    gap: 18px;
    // margin-left: 15px;
  }

  .user-nav {
    justify-content: space-between;
  }

  .section-header {
    font-size: 18px;
  }

  table,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  tr > th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #f3f5f7;
    position: relative;
    padding-left: 50%;
  }

  td::before {
    position: absolute;
    left: 10px;
  }

  tr > td {
    text-align: end;
  }

  tr > td:first-child {
    justify-content: end;
  }

  .transaction-history td:nth-of-type(1):before {
        content: "Stock";
      }
      .transaction-history td:nth-of-type(2):before {
        content: "Sector";
      }
      .transaction-history td:nth-of-type(3):before {
        content: "Quantity";
      }
      .transaction-history td:nth-of-type(4):before {
        content: "Average Price";
      }
      .transaction-history td:nth-of-type(5):before {
        content: "Value of Cost";
      }
      .transaction-history td:nth-of-type(6):before {
        content: "Percentage Changes";
      }
      .transaction-history td:nth-of-type(7):before {
        content: "Current Market Price";
      }
      .transaction-history td:nth-of-type(8):before {
        content: "Value at Current Market Price";
      }
      .transaction-history td:nth-of-type(9):before {
        content: "Unrealized PNL";
      }
      .transaction-history td:nth-of-type(10):before {
        content: "Realized PNL";
      }

  .total-box p,
  .box p {
    font-size: 14px;
  }

  .pie-chart__labels-item {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }

  .year-selector {
    gap: 12px;
  }

  .button-box {
    flex-direction: column;
    gap: 20px;
    margin-top: 28px;
  }

  .dashbord-btn {
    width: 100%;
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) {
  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  td {
    font-size: 14px;
  }

  tbody tr:hover {
    background-color: #f2f2f2;
  }
}

@media (max-width: 780px) {
  .pie-chart {
    height: 220px;
    width: 100%;
    display: inline-grid;
    justify-content: center;
    align-items: center;
  }

  .investment-overview {
    flex-direction: column; /* Switch to column layout for smaller screens */
    gap: 10px; /* Adjusted gap for smaller screens */
  }

  .price-container {
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .overall-spending {
    position: relative;
    text-align: center;
    margin: 0 auto;
    top: 27%;
    transform: translateY(-395%);
    width: 100%;
    font-size: 21px;
    max-width: 600px;
  }

  .overall-spending > h4 {
    color: gold;
    font-weight: 500;
    font-size: 14px;
    // margin-bottom: 1px;
  }

  .overall-spending > span {
    color: gold;
    font-weight: 600;
    font-size: 15px;
  }

  .transaction-history th, .transaction-history td {
    padding: 12px;
    font-size: 14px;
    text-align: right;
    color: #000000;
    height: 50px;
  }
}








/* Base styles for the table */
.transaction-history {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px; /* Add spacing below the table */
}

.transaction-header {
  background-color: #f4f4f4;
  text-align: left;
}

.transaction-header th {
  padding: 15px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  color: #333;
}

.transaction-cell {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.accordion-icon{
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .transaction-history {
    border: 0;
  }

  .transaction-row {
    display: block;
    border: 1px solid #d8d4d4;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  }

  .transaction-cell {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    font-size: 14px;
    color: #333; /* Improved text color */
    background-color: #fafafa; /* Slight background color */
  }

  .transaction-cell:nth-child(odd) {
    background-color: #f9f9f9; /* Alternating row colors */
  }

  .accordion-icon {
    transition: transform 0.3s ease;
    cursor: pointer; /* Pointer cursor on icon */
    font-size: 18px; /* Larger icon size */
    display: block;
  }

  .accordion-icon.active {
    transform: rotate(180deg);
  }

  .accordion-details {
    display: block;
    background-color: #ffffff;
    border: 1px solid #d8d4d4;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for details */
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .accordion-content {
    padding: 10px;
    box-sizing: border-box;
    max-height: 300px; /* Adjust based on your content */
    overflow-y: auto;
    font-size: 14px; /* Consistent font size */
  }

  .accordion-table {
    width: 100%;
    border-collapse: collapse;
  }

  .accordion-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: right; /* Left-align text for better readability */
  }

  .accordion-table tr:nth-child(even) {
    background-color: #f4f4f4; /* Alternating row colors */
  }

  .accordion-table td {
    font-size: 14px;
    color: #555;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .accordion-details {
    display: none;
  }
}

@media (max-width: 768px) {
  .box-size {
    height: 400px;  /* Adjust the box size for smaller screens */
  }

  .chart-container {
    height: 50%;  /* Reduce chart height for mobile view */
  }
}