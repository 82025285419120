// @import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);

* {
  font-family: Roboto;
}

svg#donut-chart {
  display: block;
  margin: 5rem auto 0 auto; /* Adjust margin-top to 7rem */
}

/* Keep other styles as they are */
text.inner-circle {
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  fill: #36454f; /* Ensure text is styled properly */
}

.arc {
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  text {
    font-weight: 300;
    font-size: 12px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  svg#donut-chart {
    display: block;
    margin: 0rem auto 0 auto;
  }
}
