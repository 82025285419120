/* Card container and card styling */
.deposite-cardBox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.deposite-card {
  flex: 1;
  padding: 25px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s;
}

.deposite-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}


/* Card text styling */
.deposite-numbers {
  font-size: 24px;
  font-weight: bold;
  transition: color 0.3s;
}

.deposite-cardName {
  font-size: 16px;
  font-weight: 600;
  color: #888;
  transition: color 0.3s;
}

/* Color-coded numbers */
.deposite-numbers.amount {
  color: #2e8b57;
}

.deposite-numbers.rate {
  color: #ff4500;
}

/* Icon styling */
.deposite-iconBx ion-icon {
  font-size: 32px;
  color: #4e2a84;
  transition: transform 0.2s, color 0.3s;
}

.deposite-iconBx:hover ion-icon {
  transform: scale(1.3);
  color: #6a1b9a;
}

/* Main container holding all charts */
.deposite-chart-main-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Responsive columns */
  gap: 20px;
  justify-items: center;
  margin: 30px;
}

/* Styling for each chart container */
.deposite-chart-container,
.deposite-chart-container-two {
  width: 100%;
  max-width: 500px;
  height: 450px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 25px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-top: 5px solid #4e2a84; /* Add a bold top border */
}

.deposite-chart-container:hover,
.deposite-chart-container-two:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

/* Heading inside chart containers */
.deposite-chart-container h2,
.deposite-chart-container-two h2 {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Make layout responsive on medium screens */
@media (max-width: 1024px) {
  .deposite-chart-main-container {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
}

/* Make layout responsive on smaller screens */
@media (max-width: 768px) {
  .deposite-chart-main-container {
    grid-template-columns: 1fr; /* Stack charts vertically */
    gap: 20px;
  }
}

/* Adjust chart container height for mobile screens */
@media (max-width: 480px) {
  .deposite-chart-container,
  .deposite-chart-container-two {
    height: 320px; /* Smaller height on mobile */
  }

  /* Card font size adjustments for mobile */
  .deposite-numbers {
    font-size: 20px;
  }
  .deposite-cardName {
    font-size: 14px;
  }
}


.deposite-div-border {
  border: 2px solid #ddd;
  padding: 10px; /* Further reduced padding */
  margin: 10px; /* Further reduced margin */
  background-color: #f9f9f9;
  border-radius: 8px; /* Smaller border radius */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

/* Enhanced styling for the year selector container */
.year-selectorr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px; /* Further reduced gap */
  background-color: #ffffff;
  padding: 2px 8px; /* Further reduced padding */
  border-radius: 8px; /* Smaller radius */
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2); /* Reduced shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 100px; /* Further reduced max width */
  margin: 2px auto;
  border: 1px solid #ddd;
}

/* Hover effect for the entire container */
.year-selectorr:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15); /* Softer shadow on hover */
}

/* Label styling */
.year-selectorr label {
  font-size: 12px; /* Further reduced font size */
  color: #4e2a84;
  font-weight: 600;
  text-align: left;
  margin-bottom: 4px; /* Reduced margin */
  transition: color 0.3s ease;
}

/* Hover effect on label */
.year-selectorr label:hover {
  color: gold;
}

/* Select box styling */
.year-selectorr select {
  padding: 6px 10px; /* Further reduced padding */
  font-size: 10px; /* Smaller font size */
  border: 2px solid gold;
  border-radius: 5px; /* Smaller border radius */
  background-color: #ffffff;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

/* Focused state for select box */
.year-selectorr select:focus {
  border-color: #ff4500;
  box-shadow: 0 0 4px rgba(255, 69, 0, 0.3); /* Glowing effect on focus */
}

/* Hover effect on select box */
.year-selectorr select:hover {
  background-color: #f9f9f9;
  border-color: #2e8b57;
}

/* Option styling */
.year-selectorr option {
  font-size: 10px; /* Further reduced font size for options */
  padding: 4px; /* Further reduced padding */
  background-color: #ffffff;
  color: #333;
  transition: background-color 0.2s ease;
}

/* Hover effect on options */
.year-selectorr option:hover {
  background-color: #f1f1f1;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .year-selectorr {
      padding: 8px; /* Further reduced padding */
      max-width: 100%; /* Full-width container on smaller screens */
  }
  .year-selectorr select {
      font-size: 8px; /* Smaller font on smaller screens */
  }
}
