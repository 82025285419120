.investment-form {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 20px; /* Space between columns */
  }
  
  .investment-row {
    margin-bottom: 20px;
    flex-basis: calc(35% - 50px);
    padding-right: 20px;
  }
  
  .investment-row label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .investment-row input[type="number"],
  .investment-row input[type="date"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-grey); /* Assuming --dark-grey is defined in your CSS */
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white); /* Assuming --white is defined in your CSS */
  }
  
  .investment-row input[type="number"]:focus,
  .investment-row input[type="date"]:focus {
    border-color: var(--blue); /* Assuming --blue is defined in your CSS */
  }
  