@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");

:root {
  --remReset: 62.5%;
}

.login-body {
  font-family: "Bricolage Grotesque", sans-serif;
  font-style: normal;
  font-size: var(--remReset);
  background-image: none;
  transition: background-image 2s smooth, margin 1s linear; /* Added margin transition */
  position: relative; /* Added */
  display: flex; /* Added */
  justify-content: center; /* Added */
  align-items: center; /* Added */
  position: relative;
}

.login-container {
  background-color: #fff;
  width: 100%;
  max-width: 310px; /* Changed */
  padding: 20px 20px; /* Moved from .login-content */
  border-radius: 1.6rem; /* Moved from media query */
  text-align: center; /* Moved from .login-content */
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px 20px;
  max-width: 310px;
  margin: 0 auto;
  text-align: center;
}

.login-content .login-para {
  font-size: 0.8rem;
  margin-top: 1rem;
}

.login-content .login-para .login-strong {
  color: #000;
}

.login-content_header {
  text-align: center;
}

.login-content_header span#logo {
  font-size: 1rem;
  font-weight: 800;
}

.login-content_header .login-header {
  font-size: 2.5rem;
  line-height: 1;
  margin: 25px 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.login-form .login-input {
  padding: 8px 16px;
  margin-bottom: 10px;
  border-radius: 50px;
}

.login-input {
  border: 1px solid #ccc;
  margin: 8px 0;
}

.login-input[type="submit"] {
  border: none;
  background-color: #000;
  color: #fffacd;
  font-weight: 800;
  letter-spacing: 1.3px;
}

.login-netoworks {
  margin-top: 1rem;
}

.login-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px;
  padding: 20px;
  cursor: pointer;
}

// ul.login-icons li {
//   background-color: rgb(177, 174, 174);
//   padding: 8px 10px;
//   border-radius: 8px;
//   list-style: none;
// }

.login-close-link {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
}

.login-close-icon {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .login-body {
    background-color: #000;
    background-image: url("https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }

  .login-container {
    right: 20px;
    max-width: 550px;
    height: 90%;
    top: 90px;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    margin: 100px auto;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .login-body {
    background-color: #000;
    background-image: url("https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }

  .login-container {
    max-width: 333px;
    height: 80%;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    // margin: 100px auto;
    text-align: center;
  }
}

@media screen and (min-width: 400px) {
  .login-container {
    border-radius: 1.6rem;
  }
}