@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.footer-container {
  background-color: #000000; /* Black background */
  color: #e7b32b; /* Gold text color */
  padding: 6rem 2rem; /* Increased padding for a more spacious look */
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.2); /* Slightly darker shadow for depth */
  border-top: 3px solid #e7b32b; /* Gold top border */
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.footer-inner {
  max-width: 90%;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer-column {
  flex: 1 1 22%; /* Better control over column width */
  min-width: 250px; /* Prevent columns from getting too narrow */
  transition: transform 0.3s ease;
}

.footer-column:hover {
  transform: translateY(-5px); /* Hover effect on columns for interactivity */
}

.footer-heading {
  font-size: 1.6rem; /* Slightly larger headings for prominence */
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #e7b32b; /* Gold color for headings */
  letter-spacing: 1px; /* Adding letter spacing for a more elegant look */
}

.footer-info {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.6;
  color: #ddd; /* Lighter color for text */
}

.footer-icon {
  margin-right: 0.5rem;
  color: #e7b32b; /* Gold color for icons */
}

.footer-social-icons {
  display: flex;
  gap: 1.5rem;
  padding-top: 1.5rem;
}

.footer-social-icon {
  background-color: #e7b32b; /* Gold background */
  color: #212121; /* Black text */
  border-radius: 50%;
  padding: 0.75rem;
  text-align: center;
  width: 3.5rem; /* Slightly larger icon size */
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Light shadow for social icons */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.footer-social-icon:hover {
  background-color: #212121; /* Black background on hover */
  color: #e7b32b; /* Gold color on hover */
  transform: scale(1.15); /* Slight zoom effect on hover */
}

.footer-link {
  color: #ffffff; /* Gold color for links */
  text-decoration: none;
  margin-bottom: 1rem;
  display: block;
  font-weight: 500;
  transition: color 0.3s ease;
  text-align: center;
}

.footer-link:hover {
  color: #f39c12; /* Lighter gold hover effect */
}

.footer-email-signup {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border: 1px solid #444;
  border-radius: 16px; /* Softer, larger corners */
  padding: 12px 16px; /* Slightly larger padding */
  background-color: #1f1f1f; /* Slightly darker for modern look */
  transition: all 0.3s ease;
}

.footer-email-signup:hover {
  border-color: #e7b32b; /* Gold border on hover */
  background-color: #2b2b2b; /* Slightly lighter background color */
}

.footer-email-input {
  flex: 1;
  padding: 0.8rem 1rem;
  border-radius: 8px; /* Rounded corners for consistency */
  border: 1px solid #444;
  background-color: #1a1a1a;
  color: #fff;
  font-size: 1rem; /* Standardized font size */
  transition: all 0.3s ease;
}

.footer-email-input::placeholder {
  color: #888; /* Softer placeholder color for better readability */
  font-style: italic; /* Subtle style for placeholder text */
}

.footer-email-input:focus {
  border-color: #e7b32b; /* Gold border on focus */
  outline: none;
  background-color: #2b2b2b; /* Lighter background on focus */
  box-shadow: 0 0 8px rgba(231, 179, 43, 0.6); /* Soft focus glow */
}

.footer-signup-button {
  padding: 0.8rem 1.2rem;
  background-color: #e7b32b; /* Gold background */
  color: #000; /* Black text for better readability */
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle button shadow */
}

.footer-signup-button:hover {
  background-color: #f39c12; /* Brighter gold */
  transform: translateY(-2px); /* Lift effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
}

.footer-signup-button:active {
  transform: translateY(0); /* Reset the lift on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reset shadow */
}

/* Increased size for Stay Connected container */
.footer-column:last-child {
  flex: 2; /* Double the size compared to other columns */
  min-width: 400px; /* Ensure it has a wider minimum width */
}

.footer-heading {
  font-size: 1.5rem;
  color: #e7b32b;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px; /* Spacing for a polished look */
  text-align: center;
}

@media (max-width: 768px) {
  .footer-row {
    flex-direction: column; /* Stack columns vertically on smaller screens */
  }

  .footer-column {
    flex: 1 1 100%; /* Ensure columns take full width on mobile */
  }

  .footer-email-signup {
    flex-direction: column; /* Stack input and button vertically on small screens */
    padding: 12px 16px; /* Adjust padding for mobile */
  }

  .footer-signup-button {
    width: 100%; /* Make the button full width on smaller screens */
    margin-top: 1rem; /* Add margin between input and button */
  }

  .footer-column:last-child {
    flex: 1 1 100%; /* Full-width column like others */
    min-width: 0; /* Allow normal behavior on smaller screens */
  }
}