.Pms_insert_user_kyc-form {
    max-width: 498px;
    display: ruby;
    /* flex-wrap: wrap; */
  }
  
  .Pms_insert_user_kyc-row {
    margin-bottom: 20px;
    flex: 0 0 calc(50% - 20px); /* Set each row to take half of the container width */
    padding-right: 20px; /* Add some space between form elements */
  }
  
  /* Label styles */
  .Pms_insert_user_kyc-row label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  /* Input styles */
  .Pms_insert_user_kyc-row input[type="text"],
  .Pms_insert_user_kyc-row input[type="password"],
  .Pms_insert_user_kyc-row input[type="date"],
  .Pms_insert_user_kyc-row input[type="email"],
  .Pms_insert_user_kyc-row input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white);
  }
  
  .Pms_insert_user_kyc-row input[type="text"]:focus,
  .Pms_insert_user_kyc-row input[type="password"]:focus,
  .Pms_insert_user_kyc-row input[type="date"]:focus,
  .Pms_insert_user_kyc-row input[type="email"]:focus,
  .Pms_insert_user_kyc-row input[type="file"]:focus {
    border-color: var(--blue);
  }
  
  /* Button styles */
  .Pms_insert_user_kyc-row button {
    margin-top: 23px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: var(--white);
    background: var(--gradient);
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .Pms_insert_user_kyc-row button:hover {
    background: linear-gradient(135deg, #5b76e3, #956fd3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .Pms_insert_user_kyc-row select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white);
  }
  
  .Pms_insert_user_kyc-row select:focus {
    border-color: var(--blue);
  }


  .viewall-container {
    padding: 20px;
    display: grid;
    grid-gap: 30px;
    background: var(--grey);
    border-radius: 20px;
    margin-bottom: 20px;
}





.section {
    background: var(--white);
    padding: 20px;
    margin-bottom: 25px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
  }
  

  .section-title {
    font-weight: 600;
    color: var(--black1);
    margin-bottom: 15px;
    border-bottom: 2px solid var(--gold);
    padding-bottom: 5px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .investment-form {
    max-width: 100%;
    /* display: flex; */
    flex-wrap: wrap; 
    display: flex;
    flex-direction: row;
  }
  

  .investment-row {
    margin-bottom: 20px;
    flex: 0 0 calc(25% - 25px); 
    padding-right: 20px; 
  }
  

  .investment-row label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  

  .investment-row input[type="text"],
  .investment-row input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white);
  }
  
  .investment-row input[type="text"]:focus,
  .investment-row input[type="password"]:focus {
    border-color: var(--blue);
  }
  

  .investment-row button {
    margin-top: 23px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: var(--white);
    background: var(--gradient);
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .investment-row button:hover {
    background: linear-gradient(135deg, #5b76e3, #956fd3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .investment-row select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    transition: border-color 0.3s ease;
    background: var(--white);
  }
  
  .investment-row select:focus {
    border-color: var(--blue);
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    font-size: 1.1em;
    border: 1px solid #c3e6cb;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    font-size: 1.1em;
    border: 1px solid #f5c6cb;
  }
  