/* Container */
.term-insurance-container {
    padding: 6rem 10%;
    text-align: center;
    background: linear-gradient(135deg, #1a1a1a, #1a1a1a);
}

/* Heading */
.term-heading {
    font-size: 3rem;
    font-weight: 700;
    color: #f7c14f;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 1.5px;
}

/* Content Section */
.term-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    backdrop-filter: blur(12px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Image Section */
.term-image-container {
    flex: 1;
    text-align: center;
}

.term-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
}

/* .term-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(231, 179, 43, 0.3);
} */

/* Text Section */
.term-text-container {
    flex: 1;
    text-align: left;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.term-subheading {
    font-size: 2rem;
    color: #e7b32b;
    font-weight: 600;
    margin-bottom: 1rem;
}

.term-description {
    font-size: 1rem;
    color: #ddd;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    text-align: justify;
}

/* Button Styling */
.learn-more-btn {
    display: inline-block;
    background: linear-gradient(135deg, #e7b32b, #d49b1f);
    color: #1a1a1a;
    padding: 14px 28px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(231, 179, 43, 0.3);
}

/* Container Styling */
.term-contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 4px 12px rgba(255, 215, 0, 0.2); */
    max-width: 1080px;
    margin: 40px auto;
}

/* Heading Styling */
.term-contact-container h3 {
    font-size: 24px;
    font-weight: 600;
    color: #e7b32b;
    margin: 0;
    flex: 1; /* Takes available space */
}

/* Button Styling */
.term-contact-button {
    background: linear-gradient(135deg, #e7b32b, #d49b1f);
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.term-contact-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(231, 179, 43, 0.3);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .term-content {
        flex-direction: column;
        text-align: center;
    }
    
    .term-text-container {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .term-heading {
        font-size: 2rem;
        margin-top: 20px;
    }

    .term-text-container {
        padding: 1rem;
    }

    .term-subheading {
        font-size: 1.8rem;
    }

    .term-description {
        font-size: 1rem;
    }

    .learn-more-btn {
        font-size: 1rem;
        padding: 12px 24px;
    }

    .term-contact-container {
        flex-direction: column;
        text-align: center;
        padding: 20px;
        gap: 15px;
    }

    .term-contact-container h3 {
        font-size: 20px;
    }

    .term-contact-button {
        font-size: 14px;
        padding: 10px 20px;
    }
}
