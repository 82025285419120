.container-xl {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  border-bottom: none;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  justify-content: center;
}

.card-body img {
  border: 5px solid #e9ecef;
}

.card-body .form-label {
  font-size: 1rem;
  color: #495057;
}

.card-body .form-label strong {
  color: #212529;
}

.card-body hr {
  border-top: 1px solid #e9ecef;
}

.card-body .row {
  margin-bottom: 1rem;
}

.myprofileimg{
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 20px;
}