/* General container styles */
.deposite-container, .deposite-cardBox, .data-table-container {
    padding: 20px 40px;
  }
  
  .man-man-div{
    background-color: rgb(243 241 241);
  }
  
  .deposite-header{
    background-color: #6d68a8;
  }
  
  /* Logo and search bar styling */
  .deposite-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 20px; */
    margin-bottom: 10px;
    margin-left: 40px;
    margin-right: 60px;
  }
  
  .deposite-logo-img,
  .deposite-logo2-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    padding: 0 30px;
  }
  
  .deposite-image-logo {
    height: 80px;
    width: 300px;
  }
  
  /* Search bar styling */
  .deposite-search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-width: 600px;
    padding: 0 20px;
  }
  
  .deposite-search-bar input {
    width: 100%;
    padding: 12px 15px;
    border-radius: 12px;
    border: 1px solid #ddd;
    font-size: 16px;
    outline: none;
  }
  
  .deposite-search-bar ion-icon {
    color: #f9f9f9;
    font-size: 20px;
    margin-left: 10px;
  }
  
  /* Page heading styling */
  .deposite-page-heading {
    color: #f8f8f8;
    font-family: 'Roboto', sans-serif;
    font-size: 2.7rem;
  }
  
  
  
  .area {
  /* Soft gold gradient background */
    background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d);
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
  }
  
  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .circles li {
    position: absolute;
    display: block;
    list-style: none;
    background: rgb(255 255 255 / 44%);
    animation: animate 25s linear infinite;
    bottom: -150px; /* Start outside the header */
    opacity: 0.7; /* Slight transparency for subtle effect */
  }
  
  /* Adjust specific circle sizes and animation delay */
  .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  
  .circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  
  .circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  
  .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  
  /* Remaining circles with adjusted positioning and delays */
  .circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  
  .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  
  .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  
  .circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  
  .circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  
  .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  
  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }
    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }
  
  
  
/* Container for the whole area */
.trading_dashboard_area {
  background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d);
  /* width: 100%; */
  /* margin-bottom: 30px; */
  /* overflow: hidden; */
  border-radius: 12px;
  position: relative;
  top: 20px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);   */
}

/* Table wrapper to allow scrolling */
.trading-table-container {
  width: 100%;
  overflow-x: auto;  /* Allows horizontal scrolling */
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px; /* Rounded corners */
  background: white;  /* Clean background for the table */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow */
  display: block; /* Ensures the container is a block-level element */
}

/* Table styling */
.trading-table {
  width: 100%;  /* Ensure the table uses 100% width */
  min-width: 800px; /* Minimum width to force scrolling */
  border-collapse: collapse;
  white-space: nowrap; /* Prevents text wrapping */
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

/* Table headers */
.trading-table th {
  padding: 12px 20px;
  text-align: left;
  background-color: gold;  /* Dark green background */
  color: white;
  font-weight: bold;
}

/* Table data cells */
.trading-table td {
  padding: 10px 20px;
  text-align: left;
  word-wrap: break-word;  /* Prevents text overflow in long words */
  border-bottom: 1px solid #ddd;
}

/* Row hover effect for better interactivity */
.trading-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Specific column widths (min-width) for better readability */
.trading-table th:nth-child(1), .trading-table td:nth-child(1) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(2), .trading-table td:nth-child(2) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(3), .trading-table td:nth-child(3) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(4), .trading-table td:nth-child(4) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(5), .trading-table td:nth-child(5) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(6), .trading-table td:nth-child(6) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(7), .trading-table td:nth-child(7) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(8), .trading-table td:nth-child(8) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(9), .trading-table td:nth-child(9) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(10), .trading-table td:nth-child(10) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(11), .trading-table td:nth-child(11) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(12), .trading-table td:nth-child(12) {
  min-width: 60px;
  text-align: center;
}
.trading-table th:nth-child(13), .trading-table td:nth-child(13) {
  min-width: 60px;
  text-align: center;
}

/* Empty state message */
.trading-table td[colspan="13"] {
  text-align: center;
  padding: 16px;
  font-style: italic;
  color: #888;
}

/* Styling for the card container */
.trading_dashboard_card {
  max-width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 15px;
  /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);  */
  margin-top: 20px;
}

/* Heading styling */
.deposit-page-heading {
  color: #000;
  font-family: 'sans-serif';
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* For smaller screens (mobile responsiveness) */
@media screen and (max-width: 768px) {
  .trading-table-container {
    padding: 10px;
  }
  
  .trading-table th, .trading-table td {
    padding: 8px 12px;  /* Adjust padding for smaller screens */
  }
}

  /* Content container to the right of the sidebar */
  .man-man-div {
    position: absolute;
    left: 165px;
    flex: 1;
    flex-grow: 1;
    padding: 20px;
    background-color: #f9f9f9;
    transition: left 0.3s ease, margin-left 0.3s ease;
    width: 88%;
  }
  
  /* Optional: Full-screen styles for mobile view */
  @media (max-width: 600px) {
    /* .container {
      flex-direction: column; 
    }
  
    .admin-sidebar {
      width: 100%; 
      height: auto;
    } */
  
    .man-man-div {
      margin-left: 0;
      padding: 10px;
    }
  }


  .trading-dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Adjust as needed for spacing */
  }
  
  .deposit-page-heading {
    font-size: 32px; /* Adjust as per your design */
    font-weight: bold;
    margin: 0;
  }
  
  .trading-deposit-search-container {
    display: flex;
  }
  
  .trading-deposit-search-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 300px; /* Adjust width as needed */
  }
  
  
 /* Table cell styling */
td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}

/* Select and file input styling inside table cells */
td select,
td input[type="file"] {
  margin: 5px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Focus state for select and file inputs */
td select:focus,
td input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Upload button styling */
.upload-btn {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Hover state for the upload button */
.upload-btn:hover {
  background-color: #0056b3;
}

/* Disabled state for the upload button */
.upload-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  td select,
  td input[type="file"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .upload-btn {
    width: 100%;
    margin-top: 10px;
  }
}

/* Download button styling */
.download-btn {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #45a049;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}

/* Focus state for the download button */
.download-btn:focus {
  outline: none; /* Removes focus outline */
}

/* Active state for the download button */
.download-btn:active {
  background-color: #388e3c; /* Even darker green when button is clicked */
  transform: scale(0.98); /* Button scales down when clicked */
}
