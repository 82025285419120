/* Container to center the content and add some padding */
.term-benefits-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem;
    /* background-color: #fff;  */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 1200px; /* Limit container width */
    margin: 2rem auto; /* Center the container */
}

/* Style for the title */
.term-benefits-title {
    font-size: 2rem;
    color: #f7c14f;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 600;
}

/* Style for each row */
.term-benefits-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 20px; /* Space between items */
    margin-bottom: 2rem;
}

/* Style for each image and text container */
.term-benefits-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 200px; /* Fixed width for better alignment */
}

/* Hover effect for items */
.term-benefits-item:hover {
    transform: translateY(-5px); /* Lift the item slightly */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Style for images */
.term-benefits-item .term-benefits-img {
    width: 80px; /* Smaller fixed width for images */
    height: 80px; /* Fixed height for consistency */
    object-fit: contain; /* Ensure images fit well */
    margin-bottom: 1rem;
}

/* Style for h3 tags */
.term-benefits-item .term-benefits-heading {
    margin-top: 0.5rem;
    font-size: 1.1rem;
    text-align: center;
    color: #333;
    font-weight: 500;
}

/* Responsive design: Adjust layout for smaller screens */
@media (max-width: 768px) {
    .term-benefits-row {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .term-benefits-item {
        width: 100%; /* Full width on smaller screens */
        max-width: 300px; /* Limit width for better readability */
    }

    .term-benefits-title {
        font-size: 1.5rem;
    }
}