@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");

:root {
  --remReset: 62.5%;
}

.signup-body {
  font-family: "Bricolage Grotesque", sans-serif;
  font-style: normal;
  font-size: var(--remReset);
  background-image: none;
  transition: background-image 2s smooth, margin 1s linear; /* Added margin transition */
  position: relative; /* Added */
  display: flex; /* Added */
  justify-content: center; /* Added */
  align-items: center; /* Added */
}

.signup-container {
  background-color: #fff;
  width: 100%;
  max-width: 310px; /* Changed */
  padding: 42px 20px; /* Moved from .signup-content */
  border-radius: 1.6rem; /* Moved from media query */
  text-align: center; /* Moved from .signup-content */
}

.signup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px 20px;
  max-width: 310px;
  margin: 0 auto;
  text-align: center;
}

.signup-content .signup-para {
  font-size: 0.8rem;
  margin-top: 2px;
}

.signup-content .signup-para .signup-strong {
  color: #000;
}

.signup-content_header {
  text-align: center;
}

.signup-content_header span#logo {
  font-size: 1rem;
  font-weight: 800;
}

.signup-content_header .signup-heading {
  font-size: 2.5rem;
  line-height: 1;
  margin: 15px 0;
}

.signup-form-list {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.signup-form .singup-input {
  padding: 8px 16px;
  border-radius: 50px;
}

.singup-input {
  border: 1px solid #ccc;
  margin: 8px 0;
}

.singup-input[type="submit"] {
  border: none;
  background-color: #000;
  color: #fffacd;
  font-weight: 800;
  letter-spacing: 1.3px;
}

.signup-netoworks {
  margin-top: 10px;
}

.signup-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 2px;
  padding: 20px;
  cursor: pointer;
}

ul.signup-icons li {
  background-color: rgb(177, 174, 174);
  padding: 8px 10px;
  border-radius: 8px;
  list-style: none;
}

/* Styling for dropdown */
.singup-select {
  padding: 7px 16px;
  border-radius: 50px;
  border: 1px solid #ccc;
  margin: 8px 0;
  width: 100%;
  font-size: 15px;
  appearance: none; /* Remove default arrow */
}

.signup-option {
  font-size: 15px;
  font-family: "Bricolage Grotesque", sans-serif;
  color: #000;
}

@media screen and (min-width: 400px) {
  .signup-container {
    border-radius: 1.6rem;
  }
}

@media screen and (min-width: 900px) {
  .signup-body {
    background-color: #000;
    background-image: url("https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .signup-container {
    right: 20px;
    max-width: 550px;
    height: 92%;
    top: 20px;
  }
  .signup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    margin-top: -3rem;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .signup-body {
    background-color: #000;
    background-image: url("https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
  .signup-container {
    max-width: 333px;
    height: 90%;
    margin-top: 20px !important;
  }

  .signup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    margin-top: -3rem;
    text-align: center;
  }
}


.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 999;
  animation: popup 2s ease-out;
}

@keyframes popup {
  from {
    opacity: 0;
    transform: translateX(-50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}
