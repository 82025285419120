.pms_dashboard_areaa {
    background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d);
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    /* left: 175px; */
    top: 20px;
}
/* Make the table scrollable horizontally */
.custom-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  /* Add padding and alignment to table cells */
  .custom-table th, .custom-table td {
    padding: 8px 12px;
    text-align: left;
    word-wrap: break-word; /* Prevent overflow of long words */
  }
  
  /* Example for setting min-width for certain columns */
  .custom-table th:nth-child(1), .custom-table td:nth-child(1) {
    min-width: 120px;
  }
  .custom-table th:nth-child(2), .custom-table td:nth-child(2) {
    min-width: 150px;
  }
  .custom-table th:nth-child(3), .custom-table td:nth-child(3) {
    min-width: 120px;
  }
  .custom-table th:nth-child(4), .custom-table td:nth-child(4) {
    min-width: 120px;
  }
  .custom-table th:nth-child(5), .custom-table td:nth-child(5) {
    min-width: 100px;
  }
  .custom-table th:nth-child(6), .custom-table td:nth-child(6) {
    min-width: 120px;
  }
  .custom-table th:nth-child(7), .custom-table td:nth-child(7) {
    min-width: 160px;
  }
  
  /* Style for empty state (no data) */
  .custom-table td[colspan="18"] {
    text-align: center;
    padding: 16px;
  }
  
  /* Ensure the table stays within the container without overflowing */
  .Pms_dashboard_card {
    max-width: 100%;
    overflow: hidden;
    padding: 10px;
  }
  
  /* Add scrollbars for small screens */
  @media screen and (max-width: 768px) {
    .custom-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
  
  .deposite-page-headingg{
    color: #f8f8f8;
    font-family: sans-serif;
    font-size: 2rem;
  }