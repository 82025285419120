/* Copyright Container */
.copyright-container {
  background-color: #212121; /* Black background */
  color: #e7b32b; /* Gold text */
  padding: 2rem; /* Padding around the content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Copyright Content */
.copyright-content {
  text-align: center; /* Center align the text */
  font-size: 1rem; /* Set font size */
}

/* Copyright Text */
.copyright-text {
  margin: 0;
  font-weight: 500; /* Slightly bolder text */
  color: white;
}

/* Copyright Company Link */
.copyright-company {
  color: #e7b32b; /* Gold color for the company link */
  text-decoration: none;
  font-weight: 600; /* Make the company link bold */
}

.copyright-company:hover {
  color: #f39c12; /* Slightly lighter gold color on hover */
}
