/* Sidebar container */
.side_side {
    width: 90px; /* Set the desired width for the sidebar */
    position: fixed; /* Fix the sidebar to the left of the viewport */
    top: 0;
    left: 0; /* Fix sidebar to the left edge */
    margin: 20px; /* Add margin for spacing */
    width: 120px;
    height: 94%; /* Full height */
    z-index: 2; /* Ensure sidebar stays above other content */
    overflow: auto; /* Allow scrolling if content overflows */
    background-color: #f9f9f9; /* Light background */
    transition: margin-left 0.3s ease; /* Smooth transition for margin */
    border-radius: 12px; /* Optional rounded corners */
  }
  
  /* Sidebar container */
  .sidebar-container {
    background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d); /* Gradient background */
    color: white; /* White text color */
    width: 100%; /* Full width inside the sidebar container */
    height: 100%; /* Full height */
    padding: 20px; /* Padding inside the sidebar */
    overflow: auto; /* Allow scrolling if content overflows */
    background-color: white; /* White background */
    border-radius: 12px; /* Rounded corners */
  }
  
  /* Navigation styling */
  .sidebar-container nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Wrapper for each icon container */
  .icon-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 21px;
    margin-bottom: 21px; /* Space between icons */
    margin-left: 7px;
  }
  
  /* Individual icon items */
  .icon-item {
    display: inline-block;
    background-color: #ffffff; /* White background for each icon container */
    border-radius: 23%; /* Round shape for icons */
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-item a {
    color: black; /* Set icon color to black */
    font-size: 30px; /* Icon size */
    transition: color 0.3s ease;
    text-decoration: none; /* Remove underlining from the link */
  }
  
  /* Hover effect for icons */
  .icon-item:hover {
    background-color: #ffc700; /* Yellow background on hover */
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }
  
  .icon-item:hover a {
    color: #fff; /* Change icon color to white on hover */
  }
  
  /* Icon font size */
  .icon-class {
    display: block;
    font-size: 32px; /* Adjust icon size */
  }
  
  /* Animated Circles */
  .animated-circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .animated-circles li {
    position: absolute;
    display: block;
    list-style: none;
    background: rgb(255 255 255 / 44%);
    animation: animate 25s linear infinite;
    bottom: -150px; /* Start outside the header */
    opacity: 0.7; /* Slight transparency for subtle effect */
  }
  
  .animated-circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  
  .animated-circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  
  .animated-circles li:nth-child(3) {
    left: 40%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  
  .animated-circles li:nth-child(4) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 16s;
  }
  
  .animated-circles li:nth-child(5) {
    left: 15%;
    width: 60px;
    height: 60px;
    animation-delay: 8s;
  }
  
  .sidebar-container:hover {
    background: linear-gradient(45deg, #ff9e00, #ffab42, #ffc107); /* Lighter gradient on hover */
  }

  .icon-wrapper {
    position: relative; /* Positioning for tooltip placement */
    display: inline-block;
  }
  
  .icon-item {
    list-style: none;
  }
  
  .icon-link {
    text-decoration: none;
    position: relative;
  }
  
  .icon-tooltip {
    visibility: hidden; /* Initially hidden */
    opacity: 0; /* Transparent */
    position: absolute;
    bottom: -30px; /* Position below the icon */
    left: 50%; /* Center align with the icon */
    transform: translateX(-50%); /* Center alignment adjustment */
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap; /* Prevent text wrapping */
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
  }
  
  .icon-link:hover .icon-tooltip {
    visibility: visible; /* Show tooltip */
    opacity: 1; /* Fully opaque */
  }
  
  