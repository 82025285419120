/* General container styles */
.admin_dashboard_container {
  padding: 20px 40px;
}

.admin_dashboard_Card {
  position: relative; /* Change from absolute to relative for better layout control */
  left: 65px; /* Remove specific left positioning to let the element flow naturally */
  right: 0;
  top: 0;
  bottom: 0;
  flex-grow: 1; /* Ensure it grows to fill available space */
  padding: 20px;
  background-color: #f9f9f9;
  transition: left 0.3s ease, margin-left 0.3s ease;
  margin: 0 auto; /* Center the card horizontally */
  width: 85%; /* Allow the card to take the full available width */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}


.admin_dashboard_area {
  background: linear-gradient(45deg, #ffd700, #ffc700, #ffae42, #f0a500, #d89a1d);
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.animated-circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.animated-circles li {
  position: absolute;
  background: rgba(255, 255, 255, 0.44);
  animation: animate 25s linear infinite;
  bottom: -150px;
  opacity: 0.7;
}

/* Circle animations */
.animated-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.animated-circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.animated-circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.animated-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

/* Logo and search bar styling */
.admin_dashboard_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 60px 10px 40px;
}

.admin_dashboard_logo_img,
.admin_dashboard_logo2_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  padding: 0 30px;
}

.admin_dashboard_image_logo {
  height: 80px;
  width: 300px;
}

/* Search bar styling */
.admin_dashboard_search_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 600px;
  padding: 0 20px;
}

.admin_dashboard_search_bar input {
  width: 100%;
  padding: 12px 15px;
  border-radius: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
}

.admin_dashboard_search_bar ion-icon {
  color: #f9f9f9;
  font-size: 20px;
  margin-left: 10px;
}

/* Page heading styling */
.admin_dashboard_page_heading {
  color: #f8f8f8;
  font-family: 'Roboto', sans-serif;
  font-size: 2.7rem;
}

/* Container for the card box */
.admin_dashboard_deposit_cardBox {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  margin: 40px;
}

/* Individual card styling */
.admin_dashboard_deposit_card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease;
}

.admin_dashboard_deposit_card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}

/* Numbers (e.g., Total Customer, Deposit Amount) styling */
.admin_dashboard_deposit_numbers {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Card name (e.g., Total Customer, Deposit Amount) styling */
.admin_dashboard_deposit_cardName {
  font-size: 0.8rem;
  color: #555;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Icon box for the card icons */
.admin_dashboard_deposit_iconBx {
  font-size: 2rem;
  color: #6d68a8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin_dashboard_deposit_cardBox {
    flex-direction: column;
    align-items: center;
  }

  .admin_dashboard_deposit_card {
    width: 90%;
    margin-bottom: 20px;
  }
}

/* Admin Details Section */
.admin-details {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the details section */
  border-radius: 8px; /* Rounded corners */
}

/* Recent Orders Section */
.admin-recent-orders {
  background-color: white;
  padding: 20px;
  border-radius: 8px; /* Rounded corners for the box */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  display: flex;
  flex-direction: column;
}

/* Card Header for Recent Orders */
.admin-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.admin-card-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333; /* Dark text for visibility */
}

.admin-card-header .admin-btn {
  padding: 8px 16px;
  background-color: #007bff; /* Primary color */
  color: white;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none; /* Remove underline from the link */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.admin-card-header .admin-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.admin-card-header .admin-btn:active {
  background-color: #00408b; /* Even darker shade when clicked */
}

@media (max-width: 768px) {
  .admin-details {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .admin-recent-orders {
    padding: 15px;
  }

  .admin-card-header h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .admin-card-header .admin-btn {
    padding: 6px 12px; /* Adjust padding for smaller buttons */
    font-size: 12px; /* Adjust font size for smaller buttons */
  }
}

.admin_dashboard_card_name {
  font-size: 2rem; /* Adjust font size */
  font-weight: bold; /* Make the text bold */
  margin-top: 10px;
  margin-left: 42px;
  color: white;
  margin-bottom: -20px;
}







.admin_dashboard_additionalInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto; /* Center horizontally */
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 60%; /* Set the width you want */
}


.admin_dashboard_date,
.admin_dashboard_mtd_ytd {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1 1;
  margin-right: 20px;
}

.admin_dashboard_date {
  margin-right: 20px;
}

.admin_dashboard_date label,
.admin_dashboard_mtd_ytd label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.admin_dashboard_date input,
.admin_dashboard_mtd_ytd select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #555;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}

.admin_dashboard_date input:hover,
.admin_dashboard_mtd_ytd select:hover {
  border-color: #007bff;
  cursor: pointer;
}

.admin_dashboard_date input:focus,
.admin_dashboard_mtd_ytd select:focus {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.admin_dashboard_date input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.admin_dashboard_mtd_ytd select {
  appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,%3csvg xmlns%3d%27http://www.w3.org/2000/svg%27 viewBox%3d%270 0 4 5%27%3e%3cpath fill%3d%27%23333%27 d%3d%27M2 0L0 2h4zM2 5L0 3h4z%27/%3e%3c/svg%3e') no-repeat right 10px center;
  background-size: 10px 10px;
}

.admin_dashboard_mtd_ytd select option {
  padding: 10px;
  color: #555;
}

@media (max-width: 768px) {
  .admin_dashboard_additionalInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .admin_dashboard_date {
    margin-right: 0;
  }
}



.admin_dashboard_date_range {
  display: flex;
  gap: 20px; /* Add space between start and end date inputs */
  margin-top: 10px; /* Add spacing between the select and the date inputs */
}

.admin_dashboard_date_range div {
  display: flex;
  flex-direction: column;
}

.admin_dashboard_date_range label {
  font-weight: bold;
  margin-bottom: 5px; /* Space between label and input */
  font-size: 14px;
  color: #333; /* Label text color */
}

.admin_dashboard_date_range input {
  padding: 8px 10px; /* Input padding for better appearance */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px;
  color: #333;
}

.admin_dashboard_date_range input:focus {
  outline: none;
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle shadow on focus */
}


/* Styling the Fetch Data button */
.fetch-data-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text color */
  padding: 12px 20px; /* Add padding for width and height */
  font-size: 16px; /* Font size */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
  height: 45px; /* Set fixed height for the button */
  line-height: 22px; /* Vertically align the text */
  display: inline-flex; /* Use inline-flex to center content */
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  margin-top: 15px;
}

/* Button hover effect */
.fetch-data-button:hover {
  background-color: #45a049; /* Slightly darker green when hovered */
  transform: scale(1.05); /* Slightly grow the button */
}

/* Add a focus effect when the button is clicked */
.fetch-data-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
}
