.call-back-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #1a1a1a;
  min-height: 100vh;
}

.call-back-container {
  max-width: 1200px;
  width: 100%;
  margin: 20px;
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
}

.call-back-header {
  background: linear-gradient(135deg, #222, #444);
  color: #ffffff;
  padding: 30px 40px;
  text-align: center;
  border-radius: 10px;

  .call-back-heading {
    margin: 0;
    font-size: 2.8rem;
    font-weight: bold;
    color: #e7b32b;
    animation: fadeIn 1s ease-in-out;
  }

  .call-back-paragraph {
    margin-top: 10px;
    font-size: 1.2rem;
    animation: slideIn 1s ease-in-out;
    color: #f2f2f2;
  }
}

.callback-content-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  margin-top: 30px;
}

.callback-image-container {
  flex: 1.3;
  max-width: 350px;
  max-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;

  .callback-image {
    width: 100%;
    max-width: 300px;
    max-height: 280px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.call-back-form {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.callback-form-group {
  position: relative;
}

.callback-input,
.callback-massage {
  width: 100%;
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #e7b32b;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(231, 179, 43, 0.5);
  }
}

.callback-label {
  position: absolute;
  top: -12px;
  left: 15px;
  background: #ffffff;
  padding: 0 8px;
  font-size: 1rem;
  color: #666;
  pointer-events: none;
}

.callback-massage {
  resize: none;
  height: 65px;
}

.callback-submit-button {
  padding: 15px 25px;
  background: linear-gradient(135deg, #e7b32b, #f2c94c);
  color: #333333;
  border: none;
  border-radius: 8px;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

/* Media Queries for Phone View */
@media (max-width: 768px) {
  .call-back-wrapper {
    padding: 20px;
  }

  .call-back-container {
    margin: 10px;
    padding: 20px;
  }

  .call-back-header {
    padding: 20px;
  }

  .callback-content-row {
    flex-direction: column;
    gap: 20px;
  }

  .callback-image-container {
    display: none;
  }

  .call-back-form {
    padding: 20px;
    grid-template-columns: 1fr; /* Stack form fields vertically */
  }

  .callback-submit-button {
    width: 100%;
    font-size: 1.1rem;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .call-back-header .call-back-heading {
    font-size: 2rem;
  }

  .call-back-header p {
    font-size: 1rem;
  }

  .callback-submit-button {
    font-size: 1.1rem;
    padding: 12px 18px;
  }
}
