:root {
  --primary-color: #e7b32b;
  --secondary-color: #34495e;
  --text-color: #fff;
  --brad: 1rem;
}

.life-insurance-dashboard {
  padding: 2rem;
  background-color: #1a1a1a;
  text-align: center;
}

.life-insurance-heading {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  font-weight: bold;
}

.life-insurance-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.life-insurance-card {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: var(--brad);
  width: calc(25% - 1rem);
  padding: 1rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color);
}

.life-insurance-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.life-insurance-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: inherit;
  transition: opacity 0.3s ease; /* Smooth overlay transition */
}

.life-insurance-card:hover .life-insurance-overlay {
  opacity: 0.7; /* Slightly reduce opacity on hover */
}

.life-insurance-title {
  position: relative;
  z-index: 2;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  transition: transform 0.3s ease; /* Smooth text transition */
}

.life-insurance-card:hover .life-insurance-title {
  transform: translateY(-3px); /* Slight lift effect on hover */
}

/* Background images for each card */
#europe1 {
  background-image: url(../../assets/images/insurancehero.avif);
}
#europe2 {
  background-image: url(../../assets/images/family_insurance.avif);
}
#europe3 {
  background-image: url(../../assets/images/car_insurance.avif);
}
#europe4 {
  background-image: url(../../assets/images/helath_insurance.jpg);
}
#europe5 {
  background-image: url(../../assets/images/bike_insurance.jpg);
}
#europe6 {
  background-image: url(../../assets/images/home_insurance.avif);
}
#europe7 {
  background-image: url(../../assets/images/travel_Insurance.avif);
}
#europe8 {
  background-image: url(../../assets/images/retirement_Plan.jpg);
}

/* Responsive Design */

/* Tablet view */
@media (max-width: 1024px) {
  .life-insurance-card {
    width: calc(33.33% - 1rem); /* 3 cards per row */
  }
}

/* Mobile Landscape and Portrait */
@media (max-width: 768px) {
  .life-insurance-card {
    width: calc(50% - 1rem); /* 2 cards per row */
  }

  .life-insurance-title {
    font-size: 1.3rem;
  }
}

/* Phone view */
@media (max-width: 480px) {
  .life-insurance-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2-column grid layout */
    gap: 1rem; /* Adjust spacing between cards */
  }

  .life-insurance-card {
    width: 100%; /* Let grid handle card width */
    height: 25vh; /* Set height to 25% of viewport height */
    padding: 0.8rem; /* Adjust padding for mobile */
  }

  .life-insurance-title {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
    margin-top: calc(25vh / 2 - 2rem); /* Center title vertically */
  }

  .life-insurance-heading {
    font-size: 1.6rem; /* Adjust heading size */
    margin-bottom: 1.5rem; /* Reduce bottom margin */
  }
}