/* ProfileMenu Container */
.menu {
  position: absolute;
  top: 50px;
  right: 20px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Gradient background */
  width: 250px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  display: none;
  z-index: 1001;
  margin-top: 3rem;
}

.menu.active {
  display: block;
}

.menu h3 {
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.menu h3 span {
  font-size: 14px;
  color: #666;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.menu ul li:hover {
  background-color: #eaeaea; /* Light background color on hover */
  border-radius: 5px; /* Rounded corners on hover */
  transform: translateY(-3px); /* Move item up slightly on hover */
}

.menu ul li img {
  width: 24px;
  margin-right: 12px;
}

.menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.menu ul li a:hover {
  color: #7c5cfc; /* Text color on hover */
}

/* Background Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Light black shadow */
  z-index: 1000; /* Ensure it is behind the menu */
}

/* Media Queries for Mobile View */
@media (max-width: 780px) {
  .menu {
    position: absolute;
    top: 110px; /* Adjusted top position */
    right: 62px; /* Align menu to the right */
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    width: 250px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    display: none;
    z-index: 1001;
    margin-top: 3rem;
  }
}
