/* Main Wrapper */
.appointment-main {
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 60px 20px;
}

/* Container */
.appointment-container {
  background-color: #000;
  border: 8px solid #e7b32b;
  padding: 50px 70px;
  border-radius: 30px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 1200px;
  width: 100%;
}

/* Title */
.appointment-title {
  font-size: 2.5rem;
  color: #e7b32b;
  font-weight: 700;
  margin-bottom: 20px;
}

/* Description */
.appointment-description {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 30px;
}

/* Form Section */
.appointment-form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

/* Input Fields */
.appointment-input {
  padding: 16px 25px;
  font-size: 1rem;
  border-radius: 30px;
  border: 2px solid black;
  outline: none;
  width: 60%;
  transition: border-color 0.3s ease-in-out;
}

/* Button */
.appointment-button {
  padding: 16px 30px;
  background-color: #e7b32b;
  color: black;
  font-size: 1.1rem;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 20%;
}

/* Button Hover Effect */
.appointment-button:hover {
  background-color: #ffb700;
  transform: translateY(-5px);
}

/* ========== MODAL POPUP STYLES ========== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a; /* Dark background */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h2 {
  color: #e7b32b; /* Highlighted text */
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.modal-content p {
  color: #e7b32b; /* Text color changed to match theme */
  font-size: 1rem;
  margin-bottom: 20px;
}

.modal-close-button {
  padding: 12px 20px;
  background-color: #000; /* Black button */
  color: #e7b32b;
  font-size: 1rem;
  border: 2px solid #e7b32b;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-close-button:hover {
  background-color: #1a1a1a; /* Darker hover effect */
  transform: scale(1.05);
}

/* ========== ANIMATIONS ========== */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* ========== MOBILE RESPONSIVENESS ========== */
@media (max-width: 768px) {
  .appointment-main {
    padding: 40px 10px;
  }

  .appointment-container {
    padding: 40px 40px;
  }

  .appointment-title {
    font-size: 2rem;
  }

  .appointment-description {
    font-size: 1rem;
  }

  .appointment-form {
    flex-direction: column;
    gap: 15px;
  }

  .appointment-input,
  .appointment-button {
    width: 80%;
    padding: 14px 20px;
  }

  .modal-content {
    max-width: 90%;
  }
}
